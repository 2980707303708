.TenPageGrvina {
  max-width: 1290px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;
  padding-top: 160px;
  margin: auto;
  &__title {
    margin-bottom: 20px;
    font-family: PretendardBold;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #1a1a19;
  }
  &__desc {
    font-family: PretendardRegular;
    font-size: 24.5px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a19;

  }
  &__heading {
    font-family: PretendardSemiBold;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #00dc16;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__left {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    grid-template-rows: 1fr 1fr;
    flex-direction: column;
    gap: 70px 0;
    margin-top: 50px;
  }
  &__item {
    justify-content: start;
    display: flex;
    align-items: start;
  }
  &__heading1 {
    font-family: PretendardSemiBold;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #00dc16;
  }
  &__heading2 {
    font-family: PretendardSemiBold;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-top: 5px;
  }
  &__dash1 {
    width: 97px;
    border: solid 2px #00dc16;
    background-color: #00dc16;
    margin-right: 45px;
    margin-left: 10px;
    margin-top: 22px;

  }
  &__dash2 {
    width: 45px;
    border: solid 2px #00dc16;
    background-color: #00dc16;
    margin-right: 45px;
    margin-left: 10px;
    margin-top: 22px;

  }
  &__desc {
    text-align: left;
  }
  &__text1 {
    font-family: PretendardRegular;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  &__text2 {
    margin-top: 8px;
    font-family: PretendardRegular;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  &__strong {
    font-family: PretendardBold;
    font-weight: bold;
  }
  &__jp {
    width: 295px;
    position: absolute;
    top: 0;
    right: 30px;
  }
  &__kr {
    position: absolute;
    width: 176px;
    bottom: 60px;
    left: 0;
  }
  &__us {
    position: absolute;
    width: 131px;
    right: 0;
    bottom: 110px;
  }
  &__etc {
    position: absolute;
    width: 90px;
    right: 80px;
    bottom: 0px;
  }
  &__right {
    position: relative;
    width: 400px;
    height: 550px;
  }
}
@media screen and (min-width: 1920px) {
  
}
@media all and (max-width: 1024px) {
 
}
@media all and (max-width: 768px) {
  .TenPageGrvina {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    &__title {
      margin-bottom: 20px;
      font-size: 24px;
      text-align: left;
    }
    &__desc {
      font-size: 14px;
      line-height: 1.5;
      text-align: center;

    }
    &__heading {
      font-size: 24px;
      line-height: 1;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: start;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
    }
    &__left {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      flex-direction: row;
      gap: 45px 0px;
      margin-top: 50px;
    }
    &__descBig {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &__item {
      justify-content: start;
      display: flex;
      align-items: start;
    }
    &__itemBig {
      display: flex;
      flex-direction: column;
      gap: 170px;
    }
    &__heading1 {
      font-size: 24px;
      text-align: left;
    }
    &__heading2 {
      font-size: 24px;
      margin-top: 5px;
    }
    &__dash1 {
      // width: 200px;
      border: solid 2px #00dc16;
      background-color: #00dc16;
      margin-right: 15px;
      margin-left: 10px;
      margin-top: 22px;

    }
    &__dash2 {
      // width: 45px;
      border: solid 2px #00dc16;
      background-color: #00dc16;
      margin-right: 15px;
      margin-left: 10px;
      margin-top: 22px;

    }
    &__desc {
      text-align: left;
    }
    &__text1 {
      font-size: 14px;
      margin-bottom: 5px;
     
    }
    &__text2 {
      font-size: 10px;
      margin-top: 15px;
    }
    .text-mobile1 {
      max-width: 135px;
    }
    .text-mobile2 {
      max-width: 165px;
    }
    .text-mobile3 {
      max-width: 195px;
    }
   
    &__jp {
      width: 295px;
      position: absolute;
      top: 20px;
      right: 5px;
    }
    &__kr {
      position: absolute;
      width: 176px;
      bottom: 60px;
      left: 0;
    }
    &__us {
      position: absolute;
      width: 131px;
      right: 0;
      bottom: 110px;
    }
    &__etc {
      position: absolute;
      width: 90px;
      right: 70px;
      bottom: 10px;
    }
    &__right {
      position: relative;
      width: 330px;
      height: 550px;
      margin: 0 auto;
    }
  }
}

