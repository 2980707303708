.AllBasicPage {
  .Button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 57px;
    border: 1px solid #ffffff;
    padding-right: 5px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    font-family: Montserrat;
    &:hover {
      background-color: #666666;
    }
    &:active {
      &:active {
        // content: url("./../../../src/_asset/images/mainback/black-download-icon.svg") !important;
      }
      background-color: #ff641f;
      color: #222;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .hrcss {
    border: 1px solid white;
  }
  .secondhrcss {
    margin-top: 68px;
    border: 1px solid white;
    margin-bottom: 10px;
  }

  .EnterProjectTitle {
    padding-top: 130px;
    font-family: Montserrat;
    font-size: 60px;
    color: #ffffff;
    max-width: 1630px;
    width: 100%;
    margin: auto;
    margin-bottom: 70px;
  }

  .InformationFillinBox {
    display: flex;
    margin-bottom: 20px;
    max-width: 1630px;
    width: 100%;
    margin: auto;
    .leftWrapper {
      padding-right: 95px;
    }
    .rightWrapper {
      padding-left: 95px;
    }
  }

  .LeftInformationBox {
    max-width: 1630px;
    width: 100%;
  }

  .ProjectNameBox {
    margin-bottom: 90px;
  }

  .RequiredElements {
    display: flex;
    margin-bottom: 34px;
  }
  .OtherRequiredElements {
    display: flex;
    margin-bottom: 44px;
  }

  .NameTitle {
    font-family: Montserrat;
    font-size: 18.5px;
    font-weight: 600;
    color: #6c6c6c;
  }

  .Emphasis {
    font-family: Montserrat;
    color: #ff641f;
    margin-left: 5px;
  }
  .ComentEmphasis {
    font-size: 14px;
    font-family: Montserrat;
    color: #ffffff;
    margin-left: 20px;
    font-weight: 300;
    margin-top: 3px;
  }

  .SelectTitle {
    max-height: 100px;
    font-family: Montserrat;
    font-size: 30px;
    color: #6c6c6c;
    font-weight: 500;
    input {
      height: 40px;
      width: 100%;
      background-color: #333;
      font-size: 30px;
      font-weight: 500;
      font-family: Montserrat;
      border: none;
      border-bottom: 2px solid white;
      padding-bottom: 20px;
      background-color: transparent;
      color: #ffffff;
      &::placeholder {
        color: white;
      }
      &:focus {
        outline: initial;
      }
    }
  }
  .DetailTitle {
    max-width: 596px;
    max-height: 100px;
    font-family: Montserrat;
    font-size: 30px;
    color: #6c6c6c;
    font-weight: 500;
  }

  .DownSelectTitleBox {
    width: 610px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .SelectSmallTitle {
    font-family: Montserrat;
    font-size: 20px;
    color: #6c6c6c;
    font-weight: 500;
    padding: 0px 0px 0px 14px;
    display: flex;
    justify-content: flex-end;
  }
  .ButtonSelectTitle {
    font-family: Montserrat;
    font-size: 30px;
    color: #6c6c6c;
    font-weight: 500;
  }

  .LineAll {
    border: 1px solid #6c6c6c;
    text-align: left;
    margin-left: 0px;
    margin-top: 18px;
  }
  .ButtonLineAll {
    border: 1px solid #6c6c6c;
    text-align: left;
    margin-left: 0px;
    margin-top: 3px;
  }

  .DownSelectTitle {
    width: 610px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .DownOtherTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 12px;
  }
  .CheckArrow {
    padding-top: 18px;
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    width: 582px;
  }
  .MuiAccordionSummary-root {
    background-color: #222;
    padding: 0px 16px;
  }
  .MuiTypography-root {
    font-family: Montserrat;
    font-size: 30px;
    color: #6c6c6c;
    font-weight: 500;
  }
  .MuiAccordionDetails-root {
    background-color: #222222;
    color: #6c6c6c;
    line-height: 1.8;
  }
  .LastContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1630px;
    width: 100%;
    margin: auto;
    padding-top: 40px;
    margin-bottom: 80px;
    .LastPrivacyLeftBox {
      .checkboxButton {
        &__wrapper {
          label {
            display: flex;
            align-items: center;
            .requiredText {
              padding-left: 14px;
            }
          }
        }
      }
      .LastButtonImg {
        width: 50px;
        height: 50px;
        border: 2px solid white;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .LastPrivacyLeftBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LastPrivacyText {
    color: #6c6c6c;
    font-size: 24px;
    font-weight: 500;
    font-family: Montserrat;
    padding-left: 20px;
  }
  .LastPrivacysSmallText {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    color: #ff641f;
  }
  .SubmitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 195px;
    height: 57px;
    border: 3px solid #6c6c6c;
    padding-right: 5px;
    font-weight: 600;
    color: #6c6c6c;
  }
  .LastPrivacyRightBox {
    color: #fff;
  }
}
@media all and (max-width: 1200px) {
  .AllBasicPage {
    .InformationFillinBox {
      .leftWrapper {
        padding-right: 30px;
      }
      .rightWrapper {
        padding-left: 30px;
      }
    }
  }
  .SelectTitle {
    font-size: 2vw !important;
    input {
      font-size: 2vw !important;
    }
  }
}
@media all and (max-width: 960px) {
  .AllBasicPage {
    .InformationFillinBox {
      .leftWrapper {
        padding-right: 12px;
      }
      .rightWrapper {
        padding-left: 12px;
      }
    }
  }
}

@media all and (max-width: 767px) {
  .AllBasicPage {
    .NameTitle {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      color: #6c6c6c;
    }
    .Button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      border: 1px solid #ffffff;
      padding-right: 5px;
      font-size: 1.7vw;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #666666;
      }
      &:active {
        &:active {
          // content: url("./../../../src/_asset/images/mainback/black-download-icon.svg") !important;
        }
        background-color: #ff641f;
        color: #222;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .hrcss {
      border: 1px solid #6c6c6c;
    }
    .secondhrcss {
      margin-top: 68px;
      border: 1px solid white;
      margin-bottom: 10px;
    }

    .EnterProjectTitle {
      padding-top: 130px;
      font-family: Montserrat;
      font-size: 3.5vw;
      color: #ffffff;
      max-width: 1630px;
      width: 100%;
      margin: auto;
      margin-bottom: 70px;
    }

    .InformationFillinBox {
      display: flex;
      margin-bottom: 20px;
      max-width: 1630px;
      width: 100%;
      margin: auto;
    }

    .LeftInformationBox {
      max-width: 1630px;
      width: 100%;
    }

    .ProjectNameBox {
      margin-bottom: 45px;
    }

    .RequiredElements {
      display: flex;
      margin-bottom: 34px;
    }
    .OtherRequiredElements {
      display: flex;
      margin-bottom: 44px;
    }

    .Emphasis {
      font-family: Montserrat;
      color: #ff641f;
      margin-left: 5px;
    }
    .ComentEmphasis {
      font-size: 14px;
      font-family: Montserrat;
      color: #ffffff;
      margin-left: 20px;
      font-weight: 300;
      margin-top: 3px;
    }

    .DetailTitle {
      max-width: 596px;
      max-height: 100px;
      font-family: Montserrat;
      font-size: 30px;
      color: #6c6c6c;
      font-weight: 500;
    }

    .DownSelectTitleBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 40px;
    }
    .SelectSmallTitle {
      font-family: Montserrat;
      font-size: 20px;
      color: #6c6c6c;
      font-weight: 500;
      padding: 0px 0px 0px 14px;
      display: flex;
      justify-content: flex-end;
    }
    .ButtonSelectTitle {
      font-family: Montserrat;
      font-size: 30px;
      color: #6c6c6c;
      font-weight: 500;
    }

    .LineAll {
      border: 1px solid #6c6c6c;
      text-align: left;
      margin-left: 0px;
      margin-top: 18px;
    }
    .ButtonLineAll {
      border: 1px solid #6c6c6c;
      text-align: left;
      margin-left: 0px;
      margin-top: 3px;
    }

    .DownSelectTitle {
      width: 610px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .DownOtherTitle {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 12px;
    }
    .CheckArrow {
      padding-top: 18px;
    }

    .MuiAccordionSummary-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 18px;
      width: 582px;
    }
    .MuiAccordionSummary-root {
      background-color: #222;
      padding: 0px 16px;
    }
    .MuiTypography-root {
      font-family: Montserrat;
      font-size: 30px;
      color: #6c6c6c;
      font-weight: 500;
    }
    .MuiAccordionDetails-root {
      background-color: #222222;
      color: #6c6c6c;
      line-height: 1.8;
    }
    .LastContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1630px;
      width: 100%;
      margin: auto;
      padding-top: 40px;
      margin-bottom: 40px;
    }
    .LastPrivacyLeftBox {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .LastPrivacyText {
      color: #6c6c6c;
      font-size: 24px;
      font-weight: 500;
      font-family: Montserrat;
      padding-left: 20px;
    }
    .LastPrivacysSmallText {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 300;
      color: #ff641f;
    }
    .SubmitButton {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 195px;
      height: 57px;
      border: 3px solid #6c6c6c;
      padding-right: 5px;
      font-weight: 600;
      color: #6c6c6c;
    }
    .LastPrivacyRightBox {
      color: #fff;
    }
  }
}
@media all and (max-width: 500px) {
  .AllBasicPage {
    .checkboxButton {
      label {
        padding-left: 5px !important;
        .requiredText {
          padding-left: 5px !important;
        }
      }
    }
    .Button {
      width: 60px;
      height: 25px;
    }
    .fileAttachmentWrapper {
      padding-bottom: 0;
      height: 48px;
    }
    .SelectTitle {
      input {
        padding-bottom: 0;
      }
    }
  }
}
@media all and (max-width: 300px) {
  .AllBasicPage {
    .Button {
      padding-right: 0;
      width: 30px;
      height: 15px;
      font-size: 4px;
    }
    .fileAttachmentWrapper {
      padding-bottom: 0;
      height: 48px;
    }
    .SelectTitle {
      input {
        padding-bottom: 0;
      }
    }
  }
}
