.customSelect {
    width: 100%;
    background-color: transparent;
    font-family: Montserrat;
    color: white;
    .MuiPaper-root {
        background-color: transparent;
    }
    .customSelect__wrapper {
        border-bottom: 2px solid white;

        .customSelect__content {
            .MuiAccordionSummary-content {
                margin: 0;
                height: 63px;
                box-sizing: border-box;
                padding-bottom: 20px;
            }
        }
    }
    .customSelect__item {
        font-size: 30px;
        font-weight: 500;
        color: white;
        &:hover {
            color: #ff641f;
            cursor: pointer;
        }
    }
    .select-placeholder {
        font-size: 30px;
        color: white;
    }
}
@media all and (max-width: 1200px) {
    .customSelect {
        .customSelect__item {
            font-size: 2vw;
        }
        .select-placeholder {
            font-size: 2vw;
        }
    }
}

@media all and (max-width: 400px) {
    .customSelect {
        &__wrapper{
            .customSelect__content {
                .MuiAccordionSummary-content{
                    padding-bottom: 0 !important;
                    height: auto !important;
                }
            }
        }
    }
}
