.n-badge .n-badge-wrap .n-badge-item.in {
  animation: 0s ease 0s 1 normal none running none;
  opacity: 1;
}

.n-badge .n-badge-wrap .n-badge-item + .n-badge-item {
  margin-left: 0.3em;
}

.n-badge .n-badge-wrap .n-badge-item.arrow,
.n-badge .n-badge-wrap .n-badge-item.mail,
.n-badge .n-badge-wrap .n-badge-item.more {
  color: transparent;
  padding: 0px;
}

.n-badge .n-badge-wrap .n-badge-item.more {
  background-color: rgba(170, 170, 170, 0.7);
  border: 1px solid rgba(170, 170, 170, 0.7);
}

.n-badge .n-badge-wrap .n-badge-item i {
  width: 1.57em;
  padding: 0.291em 0.5em;
  display: inline-block;
  box-sizing: border-box;
  color: transparent;
  background-size: contain;
  background-position: 50% center;
  background-repeat: no-repeat;
}

.n-badge .n-badge-wrap .n-badge-item.more i {
  width: 2.7em;
  position: relative;
  transform: scale(0.35);
}

i {
  font-style: normal;
}

.n-badge .n-badge-wrap .n-badge-item.more i span {
  display: inline-block;
  position: absolute;
  left: calc(50% - 0.5em);
  top: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
  background-color: rgb(255, 255, 255);
  border-radius: 9999px;
  transform: translateY(0px);
  animation-name: moreButtonDot;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.n-badge .n-badge-wrap .n-badge-item.more i span:first-child {
  left: -30%;
  animation-delay: 0ms;
}

.n-badge .n-badge-wrap .n-badge-item.more i span:nth-child(2) {
  animation-delay: 0.1s;
}

.n-badge .n-badge-wrap .n-badge-item.more i span:nth-child(3) {
  left: auto;
  right: -30%;
  animation-delay: 0.2s;
}

.n-badge .n-badge-wrap .n-badge-item {
  pointer-events: auto;
  display: inline-block;
  padding: calc(0.25em + 1px) 0.53em 0.25em;
  border-radius: 2000em;
  background-color: rgba(219, 16, 16, 0.9);
  border: 1px solid red;
  color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0.1em 0.1em 0.2em;
  font-weight: 700;
  opacity: 0;
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////추가
.n-badge .n-badge-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 100%;
  white-space: nowrap;
  transform: translateX(50%);
}
.n-badge .n-badge-wrap .n-badge-item.slide-out {
  animation: 0.3s ease 0s 1 normal both running badgeSlideOut;
}
.n-badge .n-badge-wrap .n-badge-item.in {
  animation: 0s ease 0s 1 normal none running none;
  opacity: 1;
}

.n-badge .n-badge-wrap .n-badge-item + .n-badge-item {
  margin-left: 0.3em;
}

.n-badge .n-badge-wrap .n-badge-item.arrow,
.n-badge .n-badge-wrap .n-badge-item.mail,
.n-badge .n-badge-wrap .n-badge-item.more {
  color: transparent;
  padding: 0px;
}

.n-badge .n-badge-wrap .n-badge-item.more {
  background-color: rgba(170, 170, 170, 0.7);
  border: 1px solid rgba(170, 170, 170, 0.7);
}

.n-badge .n-badge-wrap .n-badge-item i {
  width: 1.57em;
  padding: 0.291em 0.5em;
  display: inline-block;
  box-sizing: border-box;
  color: transparent;
  background-size: contain;
  background-position: 50% center;
  background-repeat: no-repeat;
}

.n-badge .n-badge-wrap .n-badge-item.more i {
  width: 2.7em;
  position: relative;
  transform: scale(0.35);
}

i {
  font-style: normal;
}

.n-badge .n-badge-wrap .n-badge-item.more i span {
  display: inline-block;
  position: absolute;
  left: calc(50% - 0.5em);
  top: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
  background-color: rgb(255, 255, 255);
  border-radius: 9999px;
  transform: translateY(0px);
  animation-name: moreButtonDot;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.n-badge .n-badge-wrap .n-badge-item.more i span:first-child {
  left: -30%;
  animation-delay: 0ms;
}

.n-badge .n-badge-wrap .n-badge-item.more i span:nth-child(2) {
  animation-delay: 0.1s;
}

.n-badge .n-badge-wrap .n-badge-item.more i span:nth-child(3) {
  left: auto;
  right: -30%;
  animation-delay: 0.2s;
}
.n-badge {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1em;
  font-size: 0.5em;
  width: 100%;
  pointer-events: none;
}
.fourpage {
  // background-image: url('../../_asset/images/mainback/bg-04.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.FooterBox {
  max-width: 1630px;
  padding: 0 30px;
  position: fixed;
  z-index: 1100;
  display: flex;
  justify-content: space-between;
  bottom: 80px;
  left: 93%;
  transform: translateX(-50%);
  align-items: flex-end;
}
//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .FooterBox {
    bottom: 50px;
    padding: 0 50px;
  }
}

@media all and (max-width: 767px) {
  .FooterBox {
    padding: 0 25px;
    bottom: 30px;
  }
}
