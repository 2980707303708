.AboutContainer {
    padding-top: 261px;

    .AboutBox {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 100%;
        .slide-item {
            img {
                width: 25vw;
                max-width: 190px;
                height: auto;
                margin: 30px;
            }
        }
        > .slick-slider {
            width: 100%;
            .slick-list {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
    .BorderBox {
        max-width: 1640px;
        width: 100%;
        margin: auto;
    }
    .Border {
        height: 4px;
        background-color: #fff;
        margin: 0 50px;
    }
    .AboutRightBox {
        width: 100%;
    }
}

@media all and (max-width: 767px) {
    .AboutContainer {
        padding-top: 150px;
    }
}
@media all and (max-width: 500px) {
    .AboutContainer {
        padding-top: 150px;
    }
    .AboutBox{
        .slide-item {
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                height: auto;
                // margin: 30px;
            }
        }
    }
}
