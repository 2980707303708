a,
a:active,
a:focus,
a:hover,
button {
  text-decoration: none;
  outline: none;
}

a {
  color: rgb(255, 255, 255);
}
.FooterMainContainer {
  margin: 0 50px;
  user-select: none;
  left: 50px;
  bottom: 50px;
  opacity: 1;
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s,
    margin 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  z-index: 1100;

  .sessions {
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: 700;
    opacity: 0.65;
  }

  .sessions > span {
    display: inline-block;
    padding-right: 21px;
    position: relative;
  }

  .sessions > span::after {
    content: "";
    position: absolute;
    right: 9px;
    top: 2px;
    display: block;
    width: 1px;
    height: 9px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .sessions > span:last-child::after {
    display: none;
  }

  // .linksBox .copyright {
  //     transition: opacity 0.3s ease 0s;
  //     opacity: 0;
  //     font-size: 3.5vw;
  //     margin-top: 9%;
  // }

  .FooterLeftBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .FooterLogoImg {
    padding-right: 80px;
  }

  .AddressBox {
    padding-top: 48px;
    color: #939393;
    font-family: Montserrat;
    font-size: 14px;
    margin-bottom: 28px;
    line-height: 25px;
    margin-right: 70px;
  }

  .ASapplicationBox {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    span {
      border-bottom: 3px solid #fff;
    }
  }

  .Informationbox {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 25px;
  }

  .hv-underline {
    position: relative;
    display: inline-block;
    line-height: 1em;
  }

  .hv-underline::after {
    content: "";
    transition: width 0.2s ease 0s, left 0.2s ease 0s, transform 0.2s ease 0s;
    width: 0px;
    height: calc(1px + 0.1em);
    background-color: rgb(255, 255, 255);
    position: absolute;
    left: 50%;
    bottom: -0.15em;
  }

  .hv-underline:hover::after,
  .underline {
    width: 100%;
    left: 0px;
  }
  .linksBox {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
  }
  .FooterMain {
    max-width: 1630px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0;
  }
  .footerbutton {
    width: 258px;
    height: 60px;
    border: solid 1px #ffffff;
    color: #ffffff;
  }
}

@media all and (max-width: 996px) {
  .FooterLeftBox {
    margin-bottom: 20px;
  }
  .copyright {
    .AddressBox {
      font-size: 2vw;
      margin-right: 5vw;
    }
    .ASapplicationBox {
      font-size: 2vw;
    }
  }
  .Informationbox {
    font-size: 2vw !important;
  }
  .FooterLogoImg {
    padding-right: 2vw !important;
  }
  .FooterMainContainer .footerbutton {
    width: 25vw;
    height: 5vw;
    font-size: 1.3vw;
  }
  .FooterMain {
    flex-wrap: wrap;
  }
}

@media all and (max-width: 768px) {
  // .FooterMainContainer{
  //   .FooterMain{
  //     .FooterLeftBox{
  //       img{
  //         display: inline;
  //       }
  //     }
  //   }
  // }
  .FooterLeftBox {
    margin-bottom: 20px;
  }
  .copyright {
    .AddressBox {
      font-size: 2vw;
      margin-right: 5vw;
    }
    .ASapplicationBox {
      font-size: 2vw;
    }
  }
  .Informationbox {
    font-size: 2vw !important;
  }
  .FooterLogoImg {
    padding-right: 2vw !important;
  }
  .FooterMainContainer .footerbutton {
    width: 25vw;
    height: 5vw;
    font-size: 1.3vw;
  }
  .FooterMain {
    flex-wrap: wrap;
  }
}

@media all and (max-width: 400px) {
  // .FooterMainContainer{
  //   .FooterMain{
  //     .FooterLeftBox{
  //       img{
  //         display: inline;
  //       }
  //     }
  //   }
  // }
  .FooterMain {
    padding: 10px !important;
  }
  .FooterLeftBox {
  }
  .copyright {
    .AddressBox {
    }
    .ASapplicationBox {
    }
  }
  .Informationbox {
  }
  .FooterLogoImg {
  }
  .FooterMainContainer .footerbutton {
  }
  .FooterMain {
  }
}
