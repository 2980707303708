.Pdiffer_container {
  overflow: hidden;
  height: 800px;
  background: #fff;
  .Pdiffer_cont {
    overflow: hidden;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    .Pdiffer_txt_box {
      margin-top: 100px;
      .tit {
        font-size: 14px;
        font-weight: 500;
      }
      .txt {
        font-size: 13px;
        padding-top: 20px;
        line-height: 1.7;
        font-weight: 500;
      }
    }
    .differ_image {
      overflow: hidden;
      margin: 136px 0 0 107px;
      width: 575px;
      height: 576px;
      > img {
        width: 100%;
      }
    }
  }
}
