.Pphone_container {
  overflow: hidden;
  .Pphone_cont_1 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    background: #f8f9fb
      url(../../../../../_asset/images/Prorenata/img_prn57.png) center top
      no-repeat;
  }
  .Pphone_cont_2 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: #f8f9fb
      url(../../../../../_asset/images/Prorenata/img_prn58.png) center top
      no-repeat;
  }
  .Pphone_tit {
    overflow: hidden;
    height: 202px;
    padding-top: 49px;
    background: #fff;
    text-align: right;
    .tit {
      font-size: 12px;
    }
  }
}
