.Enamecontainer {
  overflow: hidden;
  margin-top: 400px;

  .name_img {
    margin-right: 100px;
  }
  .name_bg {
    background: url(../../../../../_asset/images/emart/bg_name.png);
    display: block;
    overflow: hidden;
    height: 1387px;
    margin-top: 4px;
    background-size: cover;
    text-indent: -9999px;
  }
}
