.Ksection02_container {
  overflow: hidden;
  position: relative;
  .Ksection02_cont_1 {
    background: #ff0810;
    .Section02_cont {
      position: relative;
      width: 1076px;
      margin: 0 auto;
      .tit_bx {
        overflow: hidden;
        padding: 143px 0 148px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        .tit {
          font-size: 20px;
          font-weight: 600;
        }
        .txt {
          font-size: 16px;
          line-height: 1.7;
        }
      }
    }
  }
  .Ksection02_cont_2 {
    overflow: hidden;
    position: relative;
    .cont_2 {
      position: relative;
      width: 1076px;
      margin: 176px auto 226px;
      .cont_bx2 {
        width: 1076px;
        height: 878px;
        padding-top: 80px;
        background: url(../../../../../_asset/images/Kaola/img_background.png)
          no-repeat;
        text-indent: -9999px;
      }
    }
  }
  .Ksection03_cont_3 {
    overflow: hidden;
    position: relative;
    height: 1082px;
    background: #ff2f5e
      url(../../../../../_asset/images/Kaola/bg_brand_manifesto.png) 0 0
      no-repeat;
    .tit3 {
      position: absolute;
      left: 50px;
      top: 51px;
      z-index: 900;
      color: #fff;
      font-size: 12px;
    }
    .cont_3 {
      position: relative;
      width: 1076px;
      margin: 0 auto;
      padding-top: 140px;
      display: flex;
      justify-content: space-between;
      .Left {
        font-size: 25px;
        color: #fff;
        font-weight: 300;
      }
      .Right {
        font-size: 25px;
        line-height: 1.7;
        color: #fff;
        font-weight: 300;
      }
    }
  }
}
