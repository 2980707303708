.Eoverflowcontainer {
  min-height: calc((542 / 1200) * 100vh);
  padding: 100px 0;
  position: relative;
  overflow: hidden;

  &-bg {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .con_bx {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
    .Eover_title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 50px;
    }
    .Eoveer_subtext {
      font-size: 16px;
      line-height: 38px;
      color: #808080;
      margin-bottom: 100px;
    }
    .Eover_Pro {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 50px;
    }

    .Pro_ListBox {
      // width: 100%;
      // padding: 0 calc(var(--col-gap) / 2);
      // margin-bottom: var(--row-gap);
      // width: 25%;

      &_wrap {
        display: flex;
        flex-direction: column;
      }

      .titlebox {
        .title {
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 50px;
        }
      }
      .textbox {
        .text {
          margin-bottom: 25px;
          color: #222;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .Eoverflowcontainer {
    padding-top: 200px;
    .con_bx {
      // width: 800px;
      .col-3 {
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .Eoverflowcontainer {
    padding-top: 150px;
  }
}
