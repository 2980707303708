.Ptext_container {
  overflow: hidden;
  height: 800px;
  background: #fafbfb;
  .Ptext_cont {
    overflow: hidden;
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    .Image_Wrap {
      overflow: hidden;
      margin-top: 352px;
      width: 960px;
      > img {
        width: 472px;
        height: 96px;
      }
    }
  }
}
