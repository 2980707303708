.Eeventcontainer {
  position: relative;
  padding: 100px 0;
  overflow: hidden;

  .sub_text {
    overflow: hidden;
    margin: 33px 0 0 2px;
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
  }

  .event_screen {
    margin: 0 0 0 22px;
    .list_box {
      &-item1 {
        margin-top: 400px;
      }
      &-item2 {
        margin-top: 200px;
      }
      .event_Wrap {
        > img {
          width: 350px;
          height: 720px;
          border-radius: 40px;
          display: block;
          overflow: hidden;
          margin-bottom: 59px;
          box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
        }
      }

      .event_Wrap02 {
        > img {
          display: block;
          overflow: hidden;
          margin-bottom: 59px;
          box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
        }
      }
      > img {
        box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .Eeventcontainer {
    .event_screen {
      .list_box {
        .col-4 {
          width: 50%;
        }
      }
    }
  }
}
@media screen and (max-width: 830px) {
  .Eeventcontainer {
    .event_screen {
      .list_box {
        .col-4 {
          width: 100%;
        }
      }
    }
  }
}
