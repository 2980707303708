.header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1630px;
  width: 100%;
  transition: opacity 0.3s ease 0s;
  user-select: none;
  z-index: 1;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 50px 0px;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 175px;
  }
}
@media all and (max-width: 1920px) {
  .header {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 50px 0px;
    }
  }
}

@media all and (max-width: 767px) {
  .header {
    font-size: 12px;
    &__wrapper {
      margin: 10px 15px 0px;
    }
  }
}
@media all and (max-width: 500px) {
  .header {
    .header__wrapper {
      .HeaderLogoMain {
        img {
          width: 40px;
          height: 40px;
        }
      }
      .ButtonContainer {
        .btn {
          font-size: 10px;
          align-items: flex-end;
        }
      }
    }
  }
}
