.ForthPageGrvina {
  max-width: 1290px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;
  padding-top: 160px;
  margin: auto;
  &__title {
    margin-bottom: 30px;
    font-family: PretendardBold;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a19;
  }
  &__desc {
    font-family: PretendardRegular;
    font-size: 24.5px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a19;

  }
  &__content1{
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    &-item{
      padding: 30px;
      flex: 1;
      flex-direction: column;
      border-radius: 20px;
      background-color: #fff;
      display:flex;
      align-items: start;

    }
    &-img{
      max-width: 55px;
      width: 100%;
      margin-bottom: 20px;
    }
    &-text1{
      font-family: PretendardRegular;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1a1a19;
      margin-bottom: 10px;
    }
    &-text2{
      font-family: PretendardExtraBold;
      font-size: 60px;
      font-weight: bolder;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1a1a19;
      margin-bottom: 10px;
    }
    &-text3{
      font-family: PretendardRegular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1a1a19;
    }
  }
  &__content2{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    &-item{
      padding: 30px;
      flex: 1;
      flex-direction: column;
      border-radius: 20px;
      background-color: #fff;
      display:flex;
      align-items: start;

    }
    &-img{
      max-width: 55px;
      width: 100%;
      margin-bottom: 20px;
    }
    &-text1{
      font-family: PretendardRegular;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1a1a19;
      margin-bottom: 10px;
    }
    &-text2{
      font-family: PretendardExtraBold;
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1a1a19;
      margin-bottom: 10px;
    }
    &-text3{
      font-family: PretendardRegular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1a1a19;
    }
    &-img5 {
      max-width: 330px;
    }
  }
}

@media screen and (min-width: 1920px) {
  
}
@media all and (max-width: 1024px) {
  
}
@media all and (max-width: 768px) {
  .ForthPageGrvina {
    padding-top: 50px;
    padding-bottom: 50px;
    &__title {
      margin-bottom: 30px;
      font-size: 24px;
    
    }
    &__desc {
      font-size: 14px;
      line-height: 1.5;
      max-width: 320px;
      margin: 0 auto;
  
    }
    &__content1{
      margin-top: 50px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      &-item{
        padding: 30px;
        flex: 1;
        flex-direction: column;
        border-radius: 20px;
        background-color: #fff;
        display:flex;
        align-items: start;
  
      }
      &-img{
        max-width: 55px;
        width: 100%;
        margin-bottom: 20px;
      }
      &-text1{
        font-size: 14px;
        line-height: 1;
        margin-bottom: 10px;
      }
      &-text2{
        font-size: 34px;
        line-height: 1;
        margin-bottom: 10px;
      }
      &-text3{
        font-size: 14px;
        line-height: 1;
      }
    }
    &__content2{
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      &-item{
        padding: 30px;
        flex: 1;
        flex-direction: column;
        border-radius: 20px;
        background-color: #fff;
        display:flex;
        align-items: start;
  
      }
      &-img{
        max-width: 55px;
        width: 100%;
        margin-bottom: 20px;
      }
      &-text1{
        font-size: 14px;
        line-height: 1;
        margin-bottom: 10px;
      }
      &-text2{
        font-size: 34px;
        line-height: 1;
        margin-bottom: 10px;
      }
      &-text3{
        font-size: 14px;
        line-height: 1;
      }
      &-img5 {
        max-width: 250px;
        margin: 0 auto;
      }
    }
  }
}

