* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.Kcover_container {
  overflow: hidden;
  height: 1080px;
  background: #fff url(../../../../../_asset/images/Kaola/bg_cover.png) center
    380px repeat-x;
  .Kcover_cont {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
    .Kcover_cont_bx {
      position: relative;
      width: 1080px;
      height: 100%;
      .Kcover_txt1 {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 91px;
      }
      .Kcover_txt2 {
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 92px;
      }
    }
  }
}

@media screen and (max-width: 1130px) {
  .Kcover_container {
    .Kcover-img,
    .Kcover-img2 {
      width: 70%;
      img {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .Kcover_container {
    .Kcover-img,
    .Kcover-img2 {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
}
