.Tvcontainer {
  position: relative;
  min-width: 1280px;
  .black {
    padding: 252px 376px;
    background-color: #161616;
  }
  .pupple {
    width: 100%;
    height: 1343px;
    background: linear-gradient(145deg, #8300c9 14%, #120071 100%) no-repeat 50%
      50%;
    margin-bottom: 1900px;
  }
  .white {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .brand {
    width: 100%;
    height: 1222px;
    background: linear-gradient(145deg, #8300c9 14%, #120071 100%) no-repeat 50%
      50%;
    background-size: 100% 100%;
    margin-bottom: 2600px;
  }
  .video {
  }
  .connect {
    overflow: visible;
    padding: 253px 0 157px;
    background: #fafafa;
  }
  .movie {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .credit {
    padding-top: 262px;
    height: 2300px;
    background: url(../../../_asset/images/29TV/bg_footer.png) 50% 50% no-repeat;
    background-size: cover;
  }
}
