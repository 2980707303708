.Gdesigncontainer {
  overflow: hidden;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  .desc_propose {
    margin-left: 50%;
  }
  .inner {
    position: relative;
    .conbox {
      overflow: hidden;
      position: relative;
      .tit {
        color: #00c5ff;
        font-weight: 600;
        width: 98px;
        height: 17px;
      }
      .desc {
        color: #555;
        font-size: 14px;
        line-height: 28px;
        margin-top: 44px;
      }
    }
    .Top {
      overflow: hidden;
      width: 314px;
      border-radius: 40px;
      box-shadow: 60px 60px 80px rgba(0, 0, 0, 0.3);
      > img {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
    .Bot {
      overflow: hidden;
      position: relative;
      z-index: -1;
      width: 298px;
      margin: -53px 0 0 8px;
      box-shadow: 50px 50px 60px rgba(0, 0, 0, 0.05);
      > img {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
    .videoWrap {
      overflow: hidden;
      width: 298px;
      height: 530px;
      box-shadow: 60px 60px 50px rgba(0, 0, 0, 0.1);
    }
    .imgWrap {
      overflow: hidden;
      width: 298px;
      margin-top: 60px;
      box-shadow: 60px 60px 50px rgba(0, 0, 0, 0.1);
      > img {
        width: 100%;
      }
    }
  }
  .Slide_Box {
    position: relative;
    margin-top: 307px;
    .bg_circle {
      width: 880px;
      height: 880px;
      margin: 0 auto;
      border-radius: 440px;
      background: #e2f9ff;
      text-indent: -9999px;
    }
    .device_box {
      position: absolute;
      top: 117px;
      left: 50%;
      width: 314px;
      margin-left: -157px;
      > img {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
    .slide_area {
      display: flex;
      position: absolute;
      top: 319px;
      right: 0;
      left: 0;
      height: 260px;
      .slide_image_Wrap {
        float: left;
        list-style: none;
        position: relative;
        width: 260px;
        margin-right: 120px;
        > img {
          width: 260px;
          height: 260px;
        }
      }
    }
    .BBox {
      position: relative;
      margin-top: 66px;
      .BBox_inner {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        .BBox_tit {
          color: #02c6ff;
          font-weight: 600;
        }
        .BBox_txt {
          color: #555;
          font-size: 14px;
          line-height: 28px;
          margin-top: 44px;
        }
        .Image_List {
          display: flex;
          margin-top: 56px;
        }
        .Propose_C {
          overflow: hidden;
          position: relative;
          margin-top: 480px;
          .Propose_inner {
            position: relative;
            padding: 0 0 0 261px;
            width: 1200px;
            margin: 0 auto;
            .tit {
            }
            .txt {
            }
            .icon_box {
              width: 321px;
              margin-top: 25px;
              > img {
                width: 100%;
              }
            }
          }
          .left_top {
            position: absolute;
            top: 52px;
            left: 50%;
            margin-left: 25px;
          }
        }
      }
    }
  }
}

.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
}
@media screen and (max-width: 992px) {
  .Gdesigncontainer {
    .inner {
      .Top {
        box-shadow: none;
      }
      .Bot {
        margin: 0;
      }
      .imgWrap {
        margin-top: 0;
      }
    }
  }
}
