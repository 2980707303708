.Pbrand_container {
  overflow: hidden;
  height: 800px;
  background: #fafbfb;
  .Pbrand_cont {
    overflow: hidden;
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    .Pbrand_text_bx {
      overflow: hidden;
      margin-top: 100px;
      .Pbrand_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Pbrand_txt {
        font-size: 13px;
        padding-top: 20px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
    .Pbrand_list {
      overflow: hidden;
      margin-top: 126px;
      width: 960px;
      height: 317px;
      > img {
        width: 100%;
      }
    }
  }
}
