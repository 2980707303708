.Edatacontainer {
  overflow: hidden;
  position: relative;
  background-color: white;
  padding: 100px 0;
  .con_bx {
    .tx_title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 50px;
    }
    .tx_bigtitle {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 50px;
    }
    .tx_text {
      overflow: hidden;
      margin-top: 54px;
      color: #808080;
      font-size: 16px;
      line-height: 38px;
    }
    .graph {
      position: relative;
      overflow: hidden;
      margin-top: 100px;
      .category {
        overflow: hidden;
        float: left;
        width: 205px;
        .good {
          overflow: hidden;
          margin-top: 143px;
          color: #f9d009;
          font-weight: 600;
        }
        .bad {
          overflow: hidden;
          margin-top: 60px;
          color: #ff3c50;
          font-weight: 600;
        }
      }
      .graph_bx {
        overflow: hidden;
        position: relative;
        width: 994px;
        height: 418px;
        background-image: url(../../../../../_asset/images/emart/img_graph_line.png);
        background-size: 994px 418px;
        .keyword01 {
          .bad_image {
          }
        }
        .keyword02 {
        }
        .keyword03 {
        }
        .keyword04 {
        }
        .keyword05 {
        }
        .keyword06 {
        }
        .keyword07 {
        }
      }
    }
  }
}
