.AboutTopContainer {
  .AboutTopMain {
    margin-bottom: 200px;
  }
  .AboutTopTitleBox {
    margin: 0 50px;
  }
  .FontBox {
    max-width: 1630px;
    width: 100%;
    margin: auto;
  }
  .LeftTextBox {
    margin-top: 90px;
    display: flex;
    justify-content: flex-end;
  }
  .RightText {
    font-family: Trap;
    color: #ffffff;
    font-size: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
  }
  .LeftText {
    font-size: 30px;
    font-family: Trap;
    color: #ffffff;

    font-weight: 400;
    line-height: 1.4;
    text-align: center;
    span {
      border-bottom: 3px solid #fff;
    }
  }
  .firstimg {
    width: 100%;
    margin-top: 90px;
    margin-bottom: 180px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .ExprienceMain {
    max-width: 1630px;
    width: 100%;
    margin: auto;
    color: #ffffff;
    font-family: Montserrat;
  }
  .ExperienceContainer {
    margin: 0 50px;
  }
  .Value {
    font-size: 16px;
    font-family: Trap;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 40px;
  }
  .Title {
    font-family: Trap;
    font-weight: 400;
    font-size: 60px;
    padding-bottom: 35px;
    line-height: 1;
  }
  .SubText {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: left;
  }
  .AboutBrandImgBox {
    display: flex;
    align-items: center;
    max-width: 1210px;
    img {
      width: 190px;
      height: auto;
      margin: 30px;
    }
  }

  .ExprienceImgBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 70px;
  }

  .LeftImgBox {
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-right: 170px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .RightImgBox {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }

  .ImgText {
    font-family: Montserrat;
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
    line-height: 1;
  }
}

@media all and(max-width:500px) {
  .AboutTopContainer {
    .FirstImgBox {
      img {
        margin-bottom: 50px;
      }
    }
    .AboutTopMain {
      margin-bottom: 50px;
      .AboutTopTitleBox {
        .FontBox {
          .RightText {
            font-size: 30px;
          }
          .LeftTextBox {
            .LeftText {
              font-size: 15px;
            }
          }
        }
      }
    }
    .Value {
      font-size: 10px;
    }
    .Title {
      font-size: 25px;
    }
    .SubText {
      font-size: 12px;
    }
    .LeftImgBox {
      margin-right: 0;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .ImgText {
      font-size: 10px;
    }
  }
}
