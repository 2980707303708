.Pfeature_container {
  overflow: hidden;
  height: 800px;
  background: #fafbfb;
  .Pfeature_cont {
    overflow: hidden;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    .Pfeature_tit_box {
      overflow: hidden;
      margin-top: 100px;
      .tit {
        font-size: 16px;
      }
      .subtit {
        padding-top: 20px;
        font-size: 14px;
        line-height: 1.7;
      }
    }
    .Pfeature_List {
      display: flex;
      overflow: hidden;
      margin-top: 232px;

      .List_Box {
        flex: 1;
        .List_Number {
          font-size: 26px;
          font-weight: 600;
        }
        .List_NumberTit {
          font-size: 26px;
          font-weight: 600;
        }
        .List_Title {
          padding-top: 50px;
          font-size: 17px;
          font-weight: 500;
        }
        .List_Text {
          padding-top: 20px;
          font-size: 12px;
        }
      }
    }
  }
}
