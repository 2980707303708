.headerGrvina {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  transition: opacity 0.3s ease 0s;
  user-select: none;
  z-index: 1;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 1290px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .headerGrvina-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 175px;
  }
  &__backgroundBlack {
    background-color: black;
  }
}
@media all and (max-width: 1920px) {
  .headerGrvina {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 50px 0px;
      margin: 0 auto;
    }
  }
}

@media all and (max-width: 767px) {
  .headerGrvina {
    font-size: 12px;
  }
}
@media all and (max-width: 500px) {
  .headerGrvina {
    .headerGrvina__wrapper {
      .HeaderGrvinaLogoMain {
        img {
          width: 40px;
          height: 40px;
        }
      }
      .ButtonContainer {
        .btn {
          font-size: 10px;
          align-items: flex-end;
        }
      }
    }
  }
}
