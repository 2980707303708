.ElevenPage {
  background-image: url("../../../_asset/images/grvina/11-bg.webp");
  
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  &__content {
    max-width: 1290px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    position: relative;
    /* position: absolute; */
    /* left: 330px; */
    top: 165px;
    width: 100%;
    
  }
  &__title{
    font-family: PretendardRegular;
    font-size: 72px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  &__desc {
    font-family: PretendardExtraLight;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  &__img {

    margin-top: 175px;
    width: 100%;
    display: flex;
    justify-content: end;
    img {

      width: 290px;
      height: 46px;
      object-fit: contain;
      margin-bottom: 20px;
    }

  }
  &__link {
    display: block;
    font-family: PretendardRegular;
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.6;
    letter-spacing: normal;
    text-align: right;
    color: #fffefe;
    text-decoration: underline;
  
  }
}
//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .ElevenPage {
    
  }
}

@media all and (max-width: 768px) {
  .ElevenPage {
    background-color: black;
    padding-bottom: 100px;
    &__content {
      padding: 0;
      margin: 0 auto;
      position: relative;
  
      top: 50px;
      width: 100%;
      
    }
    &__title{
      margin-left: 20px;
      margin-right: 20px;
      font-size: 24px;
      line-height: 1;
      text-align: left;
    }
    &__desc {
      margin-right: 20px;
      margin-left: 20px;
      font-size: 14px;
      text-align: left;
    }
    &__img {
      margin-left: 10px;
      margin-top: 30px;
      width: 70%;
      display: flex;
      justify-content: start;
      img {
        
        width: 160px;
        height: 50px;
        object-fit: contain;
        margin-bottom: 5px;
      }
      
    }
    &__link {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 24px;
      text-align: left;
    
    }
  }
}
