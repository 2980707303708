.NinePageGrvina {
  overflow: hidden;
  position: relative;
  background-color: black;
  &__img {

    width: 1920px;
    margin: 2px 0 0;
    padding: 53px 0 0 200px;
    object-fit: contain;
  }
  &__content {
    text-align: center;

    max-width: 1290px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    position: absolute;
    top: 120px;
    margin-bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__item {
    padding: 42px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__heading {
    margin-bottom: 80px;
    font-family: PretendardBold;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  &__title {
    font-family: PretendardMedium;
    font-size: 24.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  &__line {
    border: 1px solid white;
  }
}
//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .NinePageGrvina {
    
  }
}

@media all and (max-width: 767px) {
  .NinePageGrvina {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: unset;
    
    &::before {
      background-color: black;
      content: "";
      background-image: url('../../../_asset/images/grvina/09-bgimg@3x.webp');
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: contain;
      position: absolute;
      width: 200%;
      height: 200%;
      top: 0%;
      left: -83%;
      z-index: -1;
    }
    &__img {
      display: none;
      width: 100%;
      object-fit: contain;
      padding: 0;
    }
    &__content {
      position: static;
      padding: 0px;
      width: 100%;
      margin-bottom: 20px;
      margin: 0 auto;
      transform: unset;
      max-width: 90%;
    }
    &__item {
      padding: 20px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__heading {
      margin-bottom: 20px;
      font-size: 24px;
    }
    &__title {
      font-size: 14px;
      line-height: 1.6;
    }
    &__line {
      
      border: 1px solid white;
    }
  }
}
