.Pcard_container {
  overflow: hidden;
  .Pcard_cont_1 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    background: url(../../../../../_asset/images/Prorenata/bg_prn11.png) left
      top repeat-x;
    .Pcard_bx_1 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn41.png)
        center top no-repeat;
    }
  }
  .Pcard_cont_2 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn12.png) left
      top repeat-x;
    .Pcard_bx_2 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn42.png)
        center top no-repeat;
    }
  }
  .Pcard_tit {
    overflow: hidden;

    padding: 50px 0;
    background: #fff;
    text-align: right;
    .tit {
      font-size: 12px;
    }
  }
}
