.work__grid {
  max-width: 1630px;
  width: 100%;
  overflow: hidden;
  padding: 180px 50px 0;
  box-sizing: border-box;
}

.work__list {
  margin: 0px auto;
  position: relative;
  height: 100%;
  // justify-content: center;
  // align-items: center;
  .load-more {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-top: 133px;
    cursor: pointer;
    padding: 5px 20px;
    &__text {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      color: #ff641f;
      margin-right: 25px;
    }
  }
}

.work__item {
  width: 100%;
  height: auto;
  overflow: hidden;
  vertical-align: top;
  position: relative;
  transition: 0.3s;
}

.work__item.active {
  display: inline-block;
}

.work__item.visible {
  opacity: 1;
}

.work__item:hover {
  opacity: 1;
}

.work__item a.item__link {
  // display: inline-block;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}
// 얘다
.work__item a:hover {
  .item__dimd {
    color: #222;
    opacity: 0.2;
  }
  .item__frame {
    &:hover {
      .hidden-container {
        z-index: 99;
        position: absolute;
        bottom: 0;
        left: 7px;
        padding: 20px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        .hidden-title {
          > :first-child {
            font-family: Montserrat-Bold;
            font-size: 21.5px;
            color: #ffffff;
            font-weight: 800;
          }
          > :last-child {
            font-family: Montserrat-Light;
            font-size: 14.5px;
            color: #ffffff;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.work__item a.item__link .item__frame {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  z-index: 10;
  position: relative;
  // Fade in Animations
  -webkit-animation: fade-in 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 2.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  > .hidden-container {
    display: none;
  }
}

.work__item a.item__link .item__frame .item__dimd {
  width: 100%;
  background-color: rgb(221, 221, 221);
  z-index: 20;
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

.work__item.visible .item__visual {
  visibility: visible !important;
}

.work__item:hover .item__visual {
  transform: scale(1.2) !important;
}

.work__item a.item__link .item__frame .item__visual {
  display: inline-block;
  width: 100%;
  height: 100%;
  transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  position: relative;
}

.work__item a.item__link .item__frame .item__visual .image-box {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: 10;
  transition: opacity 0.2s ease 0s;
}

.image-box {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

.work__item a.item__link .item__frame .item__visual .image-box img {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  object-fit: cover;
}

img {
  vertical-align: top;
}

.work__item:hover .badge,
.work__item:hover .video-box {
  opacity: 1 !important;
}

.work__item a.item__link .item__frame .item__visual .video-box {
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  top: 0px;
  left: 0px;
  z-index: 15;
  transition: opacity 0.4s ease 0s;
  opacity: 0;
}

.work__item a.item__link .item__frame .item__visual .video-box video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.work__item .experience__badge {
  z-index: 11;
  width: 100%;
  height: auto;
  position: absolute;
}

.work__item .experience__badge .n-badge {
  transform: translateY(10px);
  transition: transform 0.3s ease 0s, opacity 0.2s ease 0s;
  opacity: 0;
  font-size: 14px;
}

.work__item:hover .n-badge {
  opacity: 1;
  transform: translateY(0px);
}

.n-badge.center .n-badge-wrap {
  transform: translateX(0px) !important;
}

.work__item .experience__badge .n-badge .n-badge-item {
  opacity: 1;
  padding: 0.5em 1em;
}

.work__item.play .video-box {
  opacity: 1 !important;
}
.work__grid {
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: unset;
  margin: auto;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media all and (min-width: 1630px) {
  .work__grid {
    padding: 180px 10px 0 !important;
  }
}
@media all and (max-width: 400px) {
  .work__grid {
    padding: 130px 10px 0 !important;
  }
}
