.Pcover_container {
  overflow: hidden;
  height: 800px;
  background: #eff5f6 url(../../../../../_asset/images/Prorenata/bg_cover.jpg)
    center top no-repeat;
  .cont {
    position: relative;
    overflow: hidden;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    .text1 {
      overflow: hidden;
      > img {
        width: 960px;
        height: 111px;
      }
    }
    .text2 {
      position: absolute;
      left: 0;
      bottom: 0;
      overflow: hidden;
      > img {
        width: 960px;
        height: 315px;
      }
    }
  }
}
