.Plast_container {
  overflow: hidden;

  .Plast_cont_1 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    background: #f7f9fb
      url(../../../../../_asset/images/Prorenata/img_prn59.png) center top
      no-repeat;
  }
  .Plast_cont_2 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: #000 url(../../../../../_asset/images/Prorenata/img_prn60.png)
      center top no-repeat;
    background-size: 1201px 998px;
  }
  .Plast_tit_bx {
    overflow: hidden;
    height: 202px;
    padding-top: 49px;
    background: #fff;
    text-align: right;
    .tit {
      font-size: 12px;
    }
  }
}
