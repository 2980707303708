.Emartcover {
  overflow: hidden;
  height: 100%;
  padding: 50px 0;
  background: url(../../../../../_asset/images/emart/bg_cover.png) center top
    no-repeat;
  background-size: cover;
  .con_bx {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
    .logo_box {
      .Ecover_text {
        .Ecover_title {
          font-size: 35px;
          font-weight: 900;
          margin-top: 100px;
        }
        .Ecover_subtitle {
          margin-top: 50px;
          line-height: 1.7;
          font-size: 20px;
        }
      }
    }
  }
  .Ecover_Img {
    width: 364px;
    height: 752px;
    margin: 0 auto;
    .Ecover_Wrap {
      overflow: hidden;
      float: left;
      width: 100%;
      height: 100%;
      border: 15px solid #222;
      border-radius: 48px;
      box-shadow: 15px 39px 36px rgba(34, 34, 34, 0.5);
    }
  }
}

/*Renposive*/
@media screen and (max-width: 867px) {
  .Emartcover {
    &-content {
      .col-6 {
        width: 100%;
      }
    }
    &-wrapper {
      text-align: center;
    }
  }
}
