.scroll-wrapper {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
  animation-fill-mode: forwards;

  position: relative;
  // max-width: 500px;
  width: 100%;
  // padding-top: 60px;
  display: flex;
  // justify-content: space-around;
}

// @media (max-width:1380px) {
// 	.scroll-wrapper::after {
// 		animation: fadein 1s;
// 		-moz-animation: fadein 1s; /* Firefox */
// 		-webkit-animation: fadein 1s; /* Safari and Chrome */
// 		-o-animation: fadein 1s; /* Opera */
// 		animation-fill-mode: forwards;

// 		pointer-events: none; /* ignore clicks */
// 		content: "";
// 		position: absolute;
// 		z-index: 100;
// 		height: 100%;
// 		left: 0;
// 		bottom: 0;
// 		width: 100%;
// 		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,000000+20,000000+80,222222+100&0.8+0,0+20,0+80,0.8+100 */
// 		background: -moz-linear-gradient(left,  rgba(34,34,34,0.8) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(34,34,34,0.8) 100%); /* FF3.6-15 */
// 		background: -webkit-linear-gradient(left,  rgba(34,34,34,0.8) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 80%,rgba(34,34,34,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
// 		background: linear-gradient(to right,  rgba(34,34,34,0.8) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 80%,rgba(34,34,34,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// 		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc222222', endColorstr='#cc222222',GradientType=1 ); /* IE6-9 */
// 	}
// }

// @media (min-width:1380px) {
// 	.scroll-wrapper::after {
// 		// fade in Animation
// 		animation: fadeout 1s;
// 		-moz-animation: fadeout 1s; /* Firefox */
// 		-webkit-animation: fadeout 1s; /* Safari and Chrome */
// 		-o-animation: fadeout 1s; /* Opera */
// 		animation-fill-mode: forwards;

// 		pointer-events: none; /* ignore clicks */
// 		content: "";
// 		position: absolute;
// 		z-index: 100;
// 		height: 100%;
// 		left: 0;
// 		bottom: 0;
// 		width: 100%;
// 		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,000000+20,000000+80,222222+100&0.8+0,0+20,0+80,0.8+100 */
// 		background: -moz-linear-gradient(left,  rgba(34,34,34,0.8) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(34,34,34,0.8) 100%); /* FF3.6-15 */
// 		background: -webkit-linear-gradient(left,  rgba(34,34,34,0.8) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 80%,rgba(34,34,34,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
// 		background: linear-gradient(to right,  rgba(34,34,34,0.8) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 80%,rgba(34,34,34,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// 		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc222222', endColorstr='#cc222222',GradientType=1 ); /* IE6-9 */
// 	}
// }

.MenuTabContainer {
  .MenuTabMain {
    display: flex;
    position: relative;
    .MenuTabCategory {
      color: #fff;
      font-weight: 800;
      font-size: 25px;
      padding: 15px;
      cursor: pointer;
      font-family: Trap;
      display: flex;
      align-items: flex-end;
    }
    .MenuTabCategoryHover {
      display: flex;
      align-items: flex-end;
      color: #fff;
      font-weight: 800;
      font-size: 28px;
      padding: 15px;
      cursor: pointer;
      font-family: Trap;
      position: relative;
      .NumberCircle {
        -webkit-animation: scale-in-center 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;

        position: absolute;
        display: flex;
        justify-content: center;
        background-color: #00dc16;
        width: 23px;
        height: 23px;
        border-radius: 100%;
        top: 4px;
        right: 10px;
        z-index: 10;
        a {
          font-size: 12px;
          padding-top: 2px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .NumberCircle {
    display: none;
  }
  .MenuTabMain .MenuTabCategory:hover .MenuLTabText::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    color: #00dc16;
  }
  .MenuTabMain .MenuTabCategoryHover .MenuLTabText::before {
    font-weight: 800;
    font-size: 25px;
    //padding: 15px;
    cursor: pointer;
    font-family: Montserrat;
    width: 100%;
    top: 16px;
    left: 0px;
    opacity: 1;
    // transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    // 	width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    // 	opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    color: #00dc16;
  }
  .MenuTabMain .MenuLTabText::before {
    content: "";
    transition: left 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      width 0.15s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      opacity 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
    position: absolute;
    height: 3px;
    bottom: 12px;
    left: 0px;
    opacity: 0.5;
    background-color: #00dc16;
    z-index: 1000;
  }
  .MenuTabCategory {
    display: inline-block;
  }
  .MenuLTabText {
    position: relative;
    color: #ffffff;
    display: inline-block;
  }
}

// .WorksMenuTab {
//   .MenuTab_Wrap {
//     display: flex;
//     position: relative;
//     .Menu_tab {
//       color: #fff;
//       font-weight: 900;
//       font-size: 25px;
//       padding: 15px;
//     }
//   }
// }
// .MenuTab_Wrap .Menu_tab::before{
//   content: "";
//   transition: left 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
//     width 0.15s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
//     opacity 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
//   position: absolute;
//   width: 0px;
//   height: 0.06em;
//   bottom: 20px;
//   left: 0px;
//   opacity: 0.5;
//   background-color: #00dc16;
//   z-index: 1000;
// }
// .MenuTab_Wrap:hover .Menu_tab::before{
//   width: 100%;
//   left: 0px;
//   opacity: 1;
//   transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
// }

// Fade In
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Fade Out
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  /* Firefox */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

//미디어 Tablet Mobile 분기점
@media all and (max-width: 1024px) {
  .MenuTabContainer {
    .MenuTabMain {
      .MenuTabCategory {
        font-size: 20px;
        .MenuLTabText::before {
          bottom: 11px;
        }
      }
      .MenuTabCategoryHover {
        font-size: 23px;
        .MenuLTabText::before {
          bottom: 12px;
        }
      }
    }
  }
}

@media all and (max-width: 767px) {
  .MenuTabContainer {
    .MenuTabMain {
      .MenuTabCategory {
        font-size: 18px;
        padding: 10px;
        .MenuLTabText::before {
          bottom: 7px;
        }
      }
      .MenuTabCategoryHover {
        font-size: 21px;
        padding: 10px;
        .NumberCircle {
          top: 0;
          right: -2px;
          width: 19px;
          height: 19px;
          a {
            font-size: 12px;
          }
        }
        .MenuLTabText::before {
          top: 12px;
        }
      }
    }
  }
}
