@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.FirstPageGrvina {
  word-break: keep-all;
  background-color: #f4f4f4;
  position: relative;
  max-width: 1630px;
  margin: auto;
  &__content {
    color: #000;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -100%);
    text-align: center;
  }
  &__title {
    font-family: PretendardExtraBold;
    font-size: 50px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.4;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
  &__text {
    font-family: PretendardRegular;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: center;
  }
  &__green {
    color: #00dc16
  }

  .BackImg {
    background-color: #f4f4f4;
    margin-top: 350px;
    img {
      width: 100%;
    }
  }

  
  
}
@media screen and (min-width: 1920px) {
  
}
@media (max-width: 1270px) {
  .FirstPageGrvina {
   
  }
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .FirstPageGrvina {
    
    .BackImg {
      
      img {
      }
    }
  }
}

@media all and (max-width: 768px) {
  .FirstPageGrvina {
    padding-top: 115px;
    padding-bottom: 30px;
    .BackImg {
      margin-top: 0;
      overflow: hidden;
      img {
      }
    }
    &__content {
      z-index: 10;
      width: 100%;
      top: 65%;
      transform: translate(-50%, -50%);
    }
    &__title {
      font-size: 16px;
      line-height: 1;
    }
    &__text {
      font-size: 10px;
      line-height: 0.5;
    }
  }
}
