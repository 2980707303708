.PslickPage_container {
  overflow: hidden;
  height: 540px;
  background: #f1f2f4;
  .slick_box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 540px;
    margin: 0;
    .swiper-slide {
      > img {
        width: 960px;
        height: 540px;
        margin: auto;
      }
    }
    .slick-dots {
      bottom: 65px;
    }
  }
}
