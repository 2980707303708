.Pmotif_container {
  overflow: hidden;
  img {
    width: 100%;
  }
  .Pmotif_cont_1 {
    overflow: hidden;
    height: 250px;
    background: #fff;
    .Pmorif_txt_bx {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 80px;
      .Pmorif_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Pmorif_txt {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
  }
  .Pmotif_cont_2 {
    overflow: hidden;
    height: 800px;
    background: #000000
      url(../../../../../_asset/images/Prorenata/img_prn20.png) center 80px
      no-repeat;
    background-size: 1080px 595px;
  }
  .Pmotif_cont_3 {
    overflow: hidden;
    height: 800px;
    background: #f1f2f4 url(../../../../../_asset/images/Prorenata/any_prn2.gif)
      center top no-repeat;
  }
  .Pmotif_cont_4 {
    overflow: hidden;
    height: 800px;
    .Left_cont {
      overflow: hidden;
      float: left;
      width: 50%;
      height: 100%;
      background: #eff5f6
        url(../../../../../_asset/images/Prorenata/img_prn21.png) center top
        no-repeat;
      background-size: 950px 800px;
    }
    .Right_cont {
      overflow: hidden;
      float: left;
      width: 50%;
      height: 100%;
      background: #fafbfb
        url(../../../../../_asset/images/Prorenata/img_prn22.png) center top
        no-repeat;
      background-size: 950px 800px;
    }
  }
}
