.RequestMainBox {
  width: 100%;
}
.HeaderImg {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AllPage {
  max-width: 1630px;
  margin: auto;
  &__wrapper {
    margin: 0 50px;
  }
}
.RequiredServicesBox {
  .RequiredContainer {
    .required__content {
      .buttonWrapper {
        height: 100px;
      }
    }
  }
}

@media all and (max-width: 400px) {
  .AllPage {
    &__wrapper {
      margin: 0 15px;
    }
  }
}
