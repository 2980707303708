* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.Gstrategycontainer {
  padding-top: 113px;

  .desc_top {
    font-weight: 600;
    color: #10c9ff;
  }
  .desc_tit {
    font-size: 35px;
    font-weight: 900;
    line-height: 1.4;
  }
  .desc_txt {
    margin-top: 30px;
    color: #555;
    font-size: 14px;
    line-height: 28px;
  }
  .bg_area {
    background-position: 0px 41.8103%;
    overflow: hidden;
    position: relative;
    height: 500px;
    background: url(../../../../../_asset/images/g9/img_section01_1.png)
      no-repeat;
    background-size: cover;
    > span {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1029px;
      height: 75px;
      background: #fff;
      text-indent: -9999px;
    }
  }
  .txt_top_area {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    .txt_box_inner {
      position: relative;
      height: 100%;
      .tix_Wrap {
        width: 551px;
        height: 211px;
        margin-top: -112px;
        > img {
          width: 100%;
          height: auto;
          vertical-align: top;
        }
      }
      .desc_txt {
        position: absolute;
        bottom: 0;
        left: 0;
        .txt_area {
          display: flex;
          flex-direction: column;
          > span {
            display: inline-block;
            margin-top: 13px;
            padding: 0 10px;
            background: #02c6ff;
            color: #fff;
            font-size: 25px;
            line-height: 43px;
            font-weight: 300;
            &:nth-child(1) {
              width: 61px;
            }
            &:nth-child(2) {
              width: 321px;
            }
            &:nth-child(3) {
              width: 354px;
            }
            &:nth-child(4) {
              width: 436px;
            }
            &:nth-child(5) {
              width: 526px;
            }
            &:nth-child(6) {
              width: 516px;
            }
            &:nth-child(7) {
              width: 379px;
            }
          }
        }
      }
    }
  }
  .justice_area {
    position: absolute;
    top: 0;
    left: 10%;
    padding-top: 75px;
    z-index: 50;
    .justice_top {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
    }
    .justice_bottom {
      margin-top: 30px;
      color: #555;
      font-size: 14px;
      line-height: 28px;
    }
    .side_box {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: -121px;
      z-index: -10;
      width: 391px;
      height: 391px;
      border-radius: 25px;
      background: #fff
        url(../../../../../_asset/images/g9/img_txt_section01_4.png);
      background-size: 391px 391px;
      box-shadow: 40px 40px 80px rgba(0, 0, 0, 0.1);
      text-indent: -9999px;
    }
  }
  .Top_txt_bx {
    width: 357px;
    height: 167px;
    .Top_tit {
      font-size: 16px;
      font-weight: 600;
      color: #02c6ff;
    }
    .Top_title {
      margin-top: 30px;
      font-size: 35px;
      font-weight: 900;
      color: #02c6ff;
    }
  }
  .con_circle {
    right: 0;
    bottom: 0;
    width: 600px;
    height: 320px;
  }
}

@media screen and (max-width: 1300px) {
  .Gstrategycontainer {
    .justice_area {
      left: 10%;
    }
  }
}
@media screen and (max-width: 900px) {
  .Gstrategycontainer {
    .justice_area {
      left: 0%;
    }
  }
}
@media screen and (max-width: 800px) {
  .Gstrategycontainer {
    .justice_area {
      .side_box {
        right: 20%;
      }
    }
  }
}

.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
}
