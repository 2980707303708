.Equickcontainer {
  padding: 100px 0;
  overflow: hidden;
  .subtitle {
    overflow: hidden;
    margin-top: 30px;
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
  }
  .quick_list01 {
    display: inline-block;

    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
  }
  .quick_list02 {
    display: inline-block;

    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
  }
  .quick_list03 {
    display: inline-block;
    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
  }
}

@media screen and (max-width: 1200px) {
  .Equickcontainer {
    .UlScreen-content {
      .col-4 {
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Equickcontainer {
    .UlScreen-content {
      .col-4 {
        width: 100%;
      }
    }
  }
}
