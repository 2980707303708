.Esplashcontainer {
  height: 100%;
  padding: 100px 0;
  background-image: url(../../../../../_asset/images/emart/bg_splash.png);
  background-size: cover;
  overflow: hidden;
  .con_bx {
    .con_title {
      font-size: 40px;
      font-weight: 600;
    }
    .con_text {
      overflow: hidden;
      margin-top: 35px;
      color: #222;
      font-size: 16px;
      font-weight: 500;
      line-height: 38px;
    }
    .screen {
      margin-top: 150px;
      font-size: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .Esplashcontainer {
    .screen {
      .col-4 {
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Esplashcontainer {
    .screen {
      .col-4 {
        width: 100%;
      }
    }
  }
}
