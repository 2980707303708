.Edirectioncontainer {
  position: relative;
  // width: 1200px;
  // margin: 400px auto 0;
  background-color: #f3f3f3;
  padding: 100px 0;
  overflow: hidden;
  .con_bx {
    .number {
      margin-bottom: 50px;
    }
    .screen_title {
      font-size: 20px;
      font-weight: 600;
    }
    .screen_text {
      overflow: hidden;
      margin-top: 30px;
      color: #808080;
      font-size: 16px;
      line-height: 38px;
    }
  }
  .screen_bx {
    display: flex;

    // .img-inner {
    //   display: block;
    //   padding-top: calc((612 / 384) * 100%);
    //   position: relative;
    //   overflow: hidden;
    // }

    > img {
      overflow: hidden;
      border-radius: 36px;
      box-shadow: 28px 33px 37px rgba(34, 34, 34, 0.4),
        -7px 0 44px rgba(34, 34, 34, 0.4);
    }
    .em {
      display: block;
      margin-top: 50px;
      text-align: center;
      font-weight: 600;
    }

    > img {
      overflow: hidden;
      border-radius: 36px;
      box-shadow: 28px 33px 37px rgba(34, 34, 34, 0.4),
        -7px 0 44px rgba(34, 34, 34, 0.4);
    }
    .image_Wrap {
      margin-bottom: 130px;
      > img {
        overflow: hidden;
        border-radius: 36px;
        box-shadow: 28px 33px 37px rgba(34, 34, 34, 0.4),
          -7px 0 44px rgba(34, 34, 34, 0.4);
      }
    }
    .em {
      display: block;
      margin-top: 50px;
      text-align: center;
      font-weight: 600;
    }

    > img {
      overflow: hidden;
      border-radius: 36px;
      box-shadow: 28px 33px 37px rgba(34, 34, 34, 0.4),
        -7px 0 44px rgba(34, 34, 34, 0.4);
    }
    .image_Wrap {
      margin-bottom: 184px;
      > img {
        overflow: hidden;
        border-radius: 36px;
        box-shadow: 28px 33px 37px rgba(34, 34, 34, 0.4),
          -7px 0 44px rgba(34, 34, 34, 0.4);
      }
    }
    .em {
      display: block;
      margin-top: 50px;
      text-align: center;
      font-weight: 600;
    }
  }
  .txt {
    display: flex;
    padding: unset;
    .txt_area {
      overflow: hidden;
      float: left;
      width: 238px;
      margin-top: 12px;
      text-align: center;
      .txt_title {
        display: block;
        overflow: hidden;
        color: #222;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .txt_text {
        overflow: hidden;
        margin-top: 21px;
        color: #808080;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
      }
    }
  }
}
