.SixPageGrvina {
  max-width: 1290px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;
  padding-top: 105px;
  margin: auto;
  &__title {
    margin-bottom: 30px;
    font-family: PretendardBold;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a19;
  }
  &__desc {
    font-family: PretendardRegular;
    font-size: 24.5px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a19;

  }
  &__img {
    width: 100%;
    margin-top: 85px;
    margin-bottom: 100px;
  }
  
}
// .fp-tableCell{
//     vertical-align: initial !important;
// }
@media screen and (min-width: 1920px) {
 
}
@media all and (max-width: 1024px) {
  
}
@media all and (max-width: 768px) {
  .SixPageGrvina {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    margin: auto;
    &__title {
      margin-bottom: 10px;
      font-size: 24px;
    }
    &__desc {
      font-size: 14px;
  
    }
    &__img {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
}


