.Elayoutcontainer {
  margin: 100px 0;
  background-color: white;
  overflow: hidden;
  .tit_text {
    overflow: hidden;
    color: #808080;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
  }
  .layout_01 {
    display: inline-block;
    overflow: hidden;
    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4),
      -7px 0 44px rgba(34, 34, 34, 0.4);
    vertical-align: top;
  }
  .layout_02 {
    margin: 215px 0 0 60px;
    display: inline-block;
    overflow: hidden;
    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4),
      -7px 0 44px rgba(34, 34, 34, 0.4);
    vertical-align: top;
  }
  .text_title {
    font-size: 20px;
    font-weight: 600;
  }
  .text_sub {
    overflow: hidden;
    margin-top: 35px;
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
  }
  .list_image {
    font-size: 0;
    .layout_list01 {
      display: inline-block;
      // margin-right: 31px;
      vertical-align: top;
      > img {
        display: block;
        margin-bottom: 31px;
        box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
      }
    }
    .layout_list02 {
      display: inline-block;
      // margin-right: 31px;
      vertical-align: top;
      margin-top: 173px;
      > img {
        display: block;
        box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
      }
    }
    .layout_list03 {
      display: inline-block;
      // margin-right: 31px;
      vertical-align: top;
      margin-top: 491px;
      > img {
        display: block;
        margin-bottom: 31px;
        box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .Elayoutcontainer {
    .list_image {
      .col-4 {
        width: 50%;
      }
      .layout_list02 {
        margin-top: 0;
      }
      .layout_list03 {
        margin-top: 0;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Elayoutcontainer {
    .list_image {
      .col-4 {
        width: 100%;
      }
      .layout_list01,
      .layout_list02,
      .layout_list03 {
        > img {
          margin: 20px auto;
        }
      }
    }
  }
}
