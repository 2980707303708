.Pphoto_container {
  overflow: hidden;
  .Pphoto_cont_1 {
    overflow: hidden;
    width: 100%;
  }
  .Pphoto_cont_2 {
    overflow: hidden;
    width: 100%;
    border-top: 2px solid #fff;
  }
  .Pphoto_tit_bx {
    overflow: hidden;
    height: 202px;
    padding-top: 49px;
    background: #fff;
    text-align: right;
    .tit {
      font-size: 12px;
    }
  }
}
