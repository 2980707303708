* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.Gconstructioncontainer {
  .visual_box {
    position: relative;
    z-index: 80;
    height: 900px;
    margin-top: -183px;
    background-image: url(../../../../../_asset/images/g9/img_section03_9.png);
    background-size: 100% auto;
    .inner {
      position: relative;
      .desc_box {
        padding-top: 224px;
      }
      .Photo_by {
        position: absolute;
        right: -20%;
        bottom: 34px;
        width: 214px;
        height: 12px;

        > img {
          width: 100%;
        }
      }
    }
  }
  .g9_home {
    overflow: hidden;
    position: relative;
    margin-top: 319px;
    .tit_box_inner {
      position: relative;
      .tit {
        width: 90px;
        height: 52px;
        margin-left: -1px;
      }
    }
    .slide_area {
      height: 686px;
      margin-top: 87px;
    }
    .desc_box_inner {
      .inner_Textbx {
        margin-top: 86px;
        padding-left: 552px;
      }
    }
  }
  .lp_srp {
    position: relative;
    overflow: hidden;
    padding: 40px 0;
    .lp_inner {
      &-top {
        margin-bottom: 40px;
      }
      .lp_tit {
        font-size: 16px;
        font-weight: 900;
      }
      .lp_txt {
        margin-top: 30px;
        font-size: 11px;
        color: #555;
      }
      .lp_left {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        border-radius: 40px;
        box-shadow: 60px 60px 80px rgba(0, 0, 0, 0.3);
        > img {
          width: 100%;
        }
      }
      .lp_right {
        display: inline-block;
        overflow: hidden;
        width: 100%;
        border-radius: 40px;
        box-shadow: 60px 60px 80px rgba(0, 0, 0, 0.3);
        > img {
          width: 100%;
        }
      }
      .lp_desc {
        margin-top: 8px;
        .list01 {
          display: inline-block;
          position: relative;
          height: 100%;
          width: 100%;
          vertical-align: top;
          box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
            40px 40px 50px 0 rgba(0, 0, 0, 0.05);
          > img {
            width: 100%;
          }
        }
        .list02 {
          display: inline-block;
          position: relative;
          height: auto;
          width: 100%;
          height: 100%;

          vertical-align: top;
          box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
            40px 40px 50px 0 rgba(0, 0, 0, 0.05);
          > img {
            width: 100%;
          }
        }
        .list03 {
          display: inline-block;
          position: relative;
          width: 100%;
          height: 100%;
          vertical-align: top;
          box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
            40px 40px 50px 0 rgba(0, 0, 0, 0.05);
          > img {
            width: 100%;
          }
        }
        .list04 {
          display: inline-block;
          position: relative;
          width: 100%;
          height: 100%;
          vertical-align: top;
          box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
            40px 40px 50px 0 rgba(0, 0, 0, 0.05);
          > img {
            width: 100%;
          }
        }
      }
    }
    .Pc_tit {
      color: #7d7d7d;
      font-size: 11px;
      margin-bottom: 20px;
    }
  }
  .overseas {
    overflow: hidden;
    position: relative;
    margin-top: 450px;
    .top_con_inner {
      position: relative;
      padding-top: 137px;
      width: 1200px;
      margin: 0 auto;
      .over_tit_box {
        position: relative;
        margin-left: 3px;
        .over_title {
          font-size: 12px;
          font-weight: 900;
        }
        .over_text {
          margin-top: 30px;
          font-size: 10px;
          color: #9b9b9b;
        }
      }
    }
    .Desc_title {
      font-size: 30px;
      font-weight: 900;
    }
    .Desc_text {
      margin-top: 35px;
      font-size: 12px;
      line-height: 2;
    }
  }
  .bg_circle {
    position: absolute;
    top: 0;
    left: 46px;
    z-index: -2;
    width: 1110px;
    height: 1110px;
    border-radius: 555px;
    background: #fafafa;
    text-indent: -9999px;
  }
  .over_list01 {
    display: inline-block;
    position: relative;
    width: 262px;
    height: 466px;
    vertical-align: top;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
    > img {
      width: 100%;
    }
  }
  .over_list02 {
    display: inline-block;
    position: relative;
    width: 262px;
    height: 466px;
    vertical-align: top;
    margin-left: 50px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
    > img {
      width: 100%;
    }
  }
  .over_list03 {
    display: inline-block;
    position: relative;
    width: 262px;
    height: 466px;
    margin-left: 50px;
    vertical-align: top;
    margin-top: 148px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
    > video {
      width: 100%;
    }
  }
  .over_list04 {
    display: inline-block;
    position: relative;
    width: 262px;
    height: 466px;
    margin-left: 50px;
    vertical-align: top;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
    > img {
      width: 100%;
    }
  }
  .img_Wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 1200px;
    height: 688px;
    margin-right: -7px;
    border-radius: 36px;
    box-shadow: 100px 100px 150px 0 rgba(0, 0, 0, 0.1);
    > video {
      width: 100%;
      height: 100%;
      border: 15px solid #000;
      border-radius: 36px;
    }
  }
  .Brand {
    position: relative;
    .Brand_inner {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      .Brand_txtbox {
        .Brandtxt {
          font-size: 12px;
          font-weight: 900;
        }
        .Brandsubtxt {
          margin-top: 35px;
          font-size: 10px;
          color: #939393;
        }
      }
      .con_brand {
        position: relative;
        width: 678px;
        height: 2063px;
        margin: 81px auto 0;
        font-size: 0;
        .brand_image_Wrap {
          position: absolute;
          overflow: hidden;
          z-index: 60;
          border-radius: 40px;
          box-shadow: 60px 60px 80px 0 rgba(0, 0, 0, 0.3);
          > img {
            width: 100%;
          }
        }
        .brand_image_bg {
          position: absolute;
          z-index: 50;
          width: 298px;
          margin: 0 0 0 8px;
          box-shadow: 50px 50px 60px 0 rgba(0, 0, 0, 0.05);
          > img {
            width: 100%;
          }
        }
        .brand_image_Wrap02 {
          position: absolute;
          overflow: hidden;
          z-index: 60;
          border-radius: 40px;
          box-shadow: 60px 60px 80px 0 rgba(0, 0, 0, 0.3);
          > img {
            width: 100%;
          }
        }
        .brand_image_bg02 {
          position: absolute;
          z-index: 50;
          width: 298px;
          margin: 0 0 0 8px;
          box-shadow: 50px 50px 60px 0 rgba(0, 0, 0, 0.05);
          > img {
            width: 100%;
          }
        }
      }
    }
  }
}

.row {
  --col-gap: 2.4rem;
  --row-gap: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--col-gap) / 2 * -1);
  margin-bottom: calc(var(--row-gap) * -1);
}

.col {
  width: 100%;
  padding: 0 calc(var(--col-gap) / 2);
  margin-bottom: var(--row-gap);

  &-11 {
    width: calc(11 / 12 * 100%);
  }

  &-10 {
    width: calc(10 / 12 * 100%);
  }

  &-9 {
    width: calc(9 / 12 * 100%);
  }

  &-8 {
    width: calc(8 / 12 * 100%);
  }

  &-7 {
    width: calc(7 / 12 * 100%);
  }

  &-6 {
    width: 50%;
  }

  &-5 {
    width: calc(5 / 12 * 100%);
  }

  &-4 {
    width: calc(4 / 12 * 100%);
  }

  &-3 {
    width: calc(3 / 12 * 100%);
  }

  &-2 {
    width: calc(2 / 12 * 100%);
  }

  &-1 {
    width: calc(1 / 12 * 100%);
  }
}

.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
}

@media screen and (max-width: 1750px) {
  .Gconstructioncontainer {
    .visual_box {
      .inner .Photo_by {
        right: 27%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Gconstructioncontainer {
    .FindingList-content {
      .col-3 {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .Gconstructioncontainer {
    .lp_inner-top {
      .col-6 {
        width: 100%;
      }
      &-wrapper {
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Gconstructioncontainer {
    .FindingList-content {
      .col-3 {
        width: 100%;
      }
    }
  }
}
