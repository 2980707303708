.Missioncontainer {
  overflow: hidden;
  .mission_top {
    overflow: hidden;
    width: 1226px;
    margin: 0 auto 163px;
  }
  .mission_ul {
    overflow: hidden;
    position: relative;

    left: 50%;
    width: 100%;
    margin: 163px 0 272px -604px;
    .mission_01 {
      list-style: none;
      background-image: url(../../../../../_asset/images/29TV/img_mission01.jpg);
      background-position: 0 50%;
      position: relative;
      height: 220px;
      background-repeat: no-repeat;
      background-size: cover;
      .Top_number {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        position: relative;
        top: 20%;
        left: 60px;
      }
      .Image_Title {
        font-size: 50px;
        color: #fff;
        position: relative;
        top: 50%;
        left: 60px;
      }
      .Bottom_text {
        font-size: 15px;
        color: #fff;
        position: relative;
        top: 40%;
        left: 70%;
      }
    }
    .mission_02 {
      list-style: none;
      background-image: url(../../../../../_asset/images/29TV/img_mission02.jpg);
      background-position: 0 50%;
      position: relative;
      height: 220px;
      background-repeat: no-repeat;
      background-size: cover;
      .Top_number {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        position: relative;
        top: 20%;
        left: 60px;
      }
      .Image_Title {
        font-size: 50px;
        color: #fff;
        position: relative;
        top: 50%;
        left: 60px;
      }
      .Bottom_text {
        font-size: 15px;
        color: #fff;
        position: relative;
        top: 40%;
        left: 70%;
      }
    }
    .mission_03 {
      list-style: none;
      background-image: url(../../../../../_asset/images/29TV/img_mission03.jpg);
      background-position: 0 50%;
      position: relative;
      height: 220px;
      background-repeat: no-repeat;
      background-size: cover;
      .Top_number {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        position: relative;
        top: 20%;
        left: 60px;
      }
      .Image_Title {
        font-size: 50px;
        color: #fff;
        position: relative;
        top: 50%;
        left: 60px;
      }
      .Bottom_text {
        font-size: 15px;
        color: #fff;
        position: relative;
        top: 40%;
        left: 70%;
      }
    }
  }
}
