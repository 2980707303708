
.SevenPageGrvina {
  max-width: 1290px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 160px;
  padding-top: 105px;
  margin: auto;
  &__img {
    width: 100%;
    object-fit: contain;
  }
}
@media all and (min-width: 1920px) {
  .SevenPageGrvina {
  }
}

@media all and (max-width: 1200px) {
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .SevenPageGrvina {
  }
}

@media all and (max-width: 768px) {
  .SevenPageGrvina {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    margin: auto;
    &__img {
      width: 100%;
      object-fit: contain;
    }
  }
}
