.ThirdPageGrvina{
    background-color: #1a1a1a;
    display:flex;
    flex-direction: column;
    &__bg {
      width: 100%;
    }
    &__bottom {
      background-color: #1a1a1a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding-left: 330px;
      padding-right: 175px;
    }
    &__left {
     
      &-text {
        font-family: PretendardRegular;
        font-size: 60px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
      &-img {
        max-height: 26px;
      }
      &-content {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 20px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      img {
        max-height: 60px;
      }
    }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
	.ThirdPageGrvina {
	 
	}
}
  
@media all and (max-width:768px) {
	.ThirdPageGrvina {
    
    position: static!important;
    &__bottom {
      padding: 20px;
      flex-direction: column;
    }
    &__left {
      &-text {
        font-size: 24px;
      }
      &-content {
        gap: 10px;
      }
      &-img {
        max-height: 10px;
      }
    }
    &__right {
      margin-top: 10px;
      img {
        max-height: 30px;
      }
    }
	}
}