.Ksection01_container {
  overflow: hidden;
  position: relative;
  .Ksection_cont {
    position: relative;
    line-height: 0;
    .mv_bx {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: auto;
    }
  }
}
