.Econceptcontainer {
  min-height: calc((1198 / 1920) * 100vh);
  padding: 100px 0;
  position: relative;
  overflow: hidden;
  &-bg {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .litle_title {
    overflow: hidden;
    margin: 147px 0 0 10px;
    font-size: 20px;
    font-weight: 600;
  }
  .title {
    margin: 379px 0 0 18px;
    font-size: 90px;
    font-weight: 600;
  }
  .text {
    overflow: hidden;
    margin: 69px 0 0 10px;
    color: #222;
    font-size: 22px;
    line-height: 60px;
  }
}
