.Picons_container {
  overflow: hidden;
  img {
    width: 100%;
  }
  .Picons_cont_1 {
    overflow: hidden;
    height: 250px;
    background: #fff;
    .Picons_txt_bx {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 80px;
      .Picons_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Picons_txt {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
  }
  .Picons_cont_2 {
    height: 100%;
    padding: 130px 0;
    background: #f8f8f9;
    .Picons_image_Wrap {
      > img {
        width: 100%;
      }
    }
  }
  .Picons_cont_3 {
    overflow: hidden;
  }
}
