.marcketcontainer {
  overflow: hidden;
  position: relative;
  .marcket_top {
    overflow: hidden;
    width: 1226px;
    margin: 0 auto 67px;
    text-align: right;
  }
  .desc_area {
    position: relative;
    .desc_Wrap {
      position: relative;
      > video {
        width: 100%;
      }
    }
    .logo {
      position: absolute;
      top: 50%;
      left: 45%;
      > img {
        width: 270px;
        height: 97px;
      }
    }
    .top {
      position: absolute;
      top: 200px;
      right: 50%;
      width: 630px;
      margin-right: -30px;
      .block_01 {
        color: #fff;
        font-weight: 900;
      }
      .block_02 {
        margin-top: 21px;
        padding: 20px 18px 18px;
        background: #fff;
        font-weight: 900;
      }
    }
    .bottom {
      position: absolute;
      bottom: 200px;
      left: 50%;
      width: 570px;
      margin-left: 30px;
      text-align: right;
      .block_03 {
        color: #fff;
        font-weight: 900;
      }
      .block_04 {
        margin-top: 21px;
        padding: 20px 18px 18px;
        background: #fff;
        font-weight: 900;
      }
    }
  }
}
