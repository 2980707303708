.PbgPage_container {
  overflow: hidden;
  height: 800px;
  background: url(../../../../../_asset/images/Prorenata/img_prn8.jpg) center
    bottom no-repeat;
  background-size: cover;
  .Pbg_cont {
    overflow: hidden;
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    .Pbg_text_bx {
      overflow: hidden;
      position: absolute;
      left: 168px;
      top: 323px;
      .Pbg_text {
        font-size: 26px;
        text-align: center;
        color: #fefefe;
      }
    }
  }
}
