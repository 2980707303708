.ReadyPopupContainer {
  .ReadyPopupMain {
    font-family: NotoSansKr;
    text-align: center;
  }
  .ReadyPopupTextBox {
    font-size: 16px;
    padding: 50px 105px;
    font-weight: normal;
  }
  .ReadyPopupCheck {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    padding: 20px 0;
    border-top: 1px solid #eeeeee;
    color: #00dc16;
  }
}
.MuiPaper-rounded {
  border-radius: 10px !important;
}
