.Psystem_container {
  overflow: hidden;
  .Psystem_cont_1 {
    overflow: hidden;
    height: 250px;
    background: #fff;
    .Psystem_txt_bx {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 80px;
      .Psystem_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Psystem_txt {
        font-size: 13px;
        padding-top: 20px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
  }
  .Psystem_cont_2 {
    overflow: hidden;
    height: 800px;
    background: #c3c8d0;
    .Psystem_img_Wrap {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 144px;
      > img {
        width: 100%;
      }
    }
  }
}
