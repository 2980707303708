.Ptypography_container {
  overflow: hidden;
  .Ptypo_cont_1 {
    overflow: hidden;
    height: 250px;
    background: #fff;
    .Ptypo_txt_bx {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 80px;
      .Ptypo_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Ptypo_txt {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
  }
  .Ptypo_cont_2 {
    overflow: hidden;
    height: 800px;
    background: #c3c8d0;
    .Ptypo_image_Wrap {
      overflow: hidden;
      padding-top: 153px;
      > img {
        width: 100%;
      }
    }
  }
  .Ptypo_cont_3 {
    overflow: hidden;
    height: 800px;
    background: url(../../../../../_asset/images/Prorenata/img_prn19.jpg) center
      top no-repeat;
    background-size: cover;
    .Ptypo_image_Wrap_2 {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 288px;
      > img {
        width: 100%;
      }
    }
  }
}
.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
}
