.ButtonContainer {
  display: flex;
  align-items: flex-end;
  &.black {
    .menubtn {
      color: black;
    }
    .menuIcon {
      fill: black;
    }
  }
  .menubtn {
    margin-right: 4px;
    color: #ffffff;
    font-family: Trap;
    font-family: 500;
  }
  .btn a {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .btn {
    padding-right: initial;
    font-size: 15px;
    font-weight: 500;
  }
  .btn:hover {
    .menuIcon {
      fill: #00dc16;
    }
    .menubtn {
      color: #00dc16;
    }
  }
  .menuIcon {
    fill: #fff;
  }
}
@media (max-width: 1270px) {
  .ButtonContainer {
    .btn {
      font-size: 13px;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .ButtonContainer {
    .menubtn {
      line-height: 1;
    }
    .btn {
      align-items: flex-start;
      font-size: 16px;
    }
    .btn.about {
      display: none;
    }
  }
}

@media all and (max-width: 400px) {
  .ButtonContainer {
    .btn {
      font-size: 14px;
    }
  }
}

@media all and (max-width: 300px) {
  .ButtonContainer {
    .btn {
      font-size: 12px;
    }
  }
}
