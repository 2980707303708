.HeaderLogo {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Montserrat";
  line-height: 1;
}
@media (max-width:1270px) {
  .HeaderLogo{
    font-size: 29px;
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:767px) {
  .HeaderLogo{
    font-size: 25px;
  }
}
@media all and (max-width:400px) {
  .HeaderLogo{
    font-size: 23px;
  }
}

@media all and (max-width:350px) {
  .HeaderLogo{
    font-size: 20px;
  }
}

@media all and (max-width:300px) {
  .HeaderLogo{
    font-size: 17px;
  }
}

.HeaderLogeMain{
  margin-left: 10px;

}