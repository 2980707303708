// 백그라운드 영상 overflow 에 따른 Hidden 처리
.fp-section.fp-table,
.fp-slide.fp-table {
  overflow: hidden;
}
.custom-dot {
  width: 13px;
  height: 13px;
  background-color: #fff;
  cursor: pointer;
  &-active {
    background-color: black;
    cursor: pointer;
  }
}
.SecondPageGrvina {
  &__title {
    margin-top: 150px;
    font-family: PretendardBold;
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  &__desc {
    margin-bottom: 60px;
    font-family: PretendardRegular;
    font-size: 24.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

  }
  &__slider-item {
    max-width: 480px;
    position: relative;
    margin: 15px;
  }
  &__image {
    width: 100%;
  }
  &__icon1 {
    position: absolute;
    right: 10px;
    top: 40px;
    max-height: 50px;
  }
  &__icon2 {
    max-height: 50px;
    position: absolute;
    right: 10px;
    bottom: 25px;
  }
  &__icon3 {
    max-height: 50px;
    position: absolute;
    left: 40px;
    bottom: 25px;
  }
  .slick-dots li button {
    background-color: white;
    width: 13px;
    height: 13px;
    border-radius: 100rem;
  }
  .slick-dots li button::before {
    content: none;
  }
  .slick-active button {
    background-color: black !important;
  }
  
}
@media all and (min-width: 1920px) {
  .SecondPageGrvina {
  }
}

@media all and (max-width: 1200px) {
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .SecondPageGrvina {
    
  }
}

@media all and (max-width: 768px) {
  .SecondPageGrvina {
    padding: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px;
    &__title {
      margin-top: 0px;
      font-size: 24px;
      
    }
    &__desc {
      margin-bottom: 40px;
      font-size: 14px;
      
  
    }
    &__slider-item {
      max-width: 300px;
      position: relative;
      margin: 15px;
    }
    &__image {
      width: 100%;
    }
    &__icon1 {
      position: absolute;
      right: -30px;
      top: 40px;
      max-height: 40px;
    }
    &__icon2 {
      max-height: 40px;
      position: absolute;
      right: -20px;
      bottom: 10px;
    }
    &__icon3 {
      max-height: 40px;
      position: absolute;
      left: 10px;
      bottom: 10px;
    }
    .slick-slider, .slick-initialized {
      max-height: 350px !important;
      margin-bottom: 30px !important;
    }
    .slick-arrow {
      display: none !important;
    }

    .slick-dots li {
      margin: 0 1px;
    }
    .slick-dots li button {
      background-color: white;
      width: 11px;
      height: 11px;
      border-radius: 100rem;
    }
    .slick-dots li button::before {
      content: none;
    }
    .slick-active button {
      background-color: black !important;
    }
  }
}
