.Prender_container {
  overflow: hidden;

  .Prender_cont_1 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    background: url(../../../../../_asset/images/Prorenata/bg_prn9.png) left top
      repeat-x;
    .Ptender_bx_01 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn37.png)
        center top no-repeat;
    }
  }
  .Prender_cont_2 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/img_prn38.png) center
      top repeat-x;
  }
  .Prender_cont_3 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: #dfe2e6;
    .Ptender_bx_02 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn39.png)
        center top no-repeat;
    }
  }
  .Prender_cont_4 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn10.png) left
      top repeat-x;
    .Ptender_bx_03 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn40.png)
        center top no-repeat;
    }
  }
  .Ptender_tit {
    overflow: hidden;
    padding: 50px 0;
    background: #fff;

    .Left_bx {
      font-size: 12px;
    }
    .Right_bx {
      font-size: 12px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
    }
  }
}
