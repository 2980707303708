.News1Container {
  background-color: #ffffff;
  padding-top: 180px;
  .New1Main {
    max-width: 1580px;
    margin: auto;
    padding: 0 50px;
  }
  .ImgBox {
    img {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .News1LeftBox {
    position: initial;
  }
  .News1TitleBox {
    display: flex;
    justify-content: space-between;
    font-family: Trap;
    align-items: center;
    margin-bottom: 40px;
  }
  .News1RightBox {
    .News1Sub {
      padding-top: 5px;
    }
  }
  .News1Title {
    white-space: nowrap;
    font-size: 25px;
    font-weight: 800;
    margin-right: 30px;
  }
  .News1Sub {
    font-size: 14px;
    font-weight: 300;
    color: #cccccc;
  }
  .News1Sort {
    font-size: 14.5px;
    font-weight: 300;
  }
}

@media all and (max-width: 1300px) {
  .News1Container {
    .News1Title {
      font-size: 23px;
      margin-right: 20px;
    }
    .News1Sub {
      font-size: 13px;
    }
    .News1Sort {
      font-size: 13.5px;
    }
  }
}

@media all and (max-width: 1200px) {
  .News1Container {
    .News1Title {
      font-size: 23px;
      margin-right: 20px;
    }
    .News1RightBox {
      align-items: flex-end;
    }
    .News1Sub {
      font-size: 13px;
    }
    .News1Sort {
      font-size: 13.5px;
    }
  }
}
@media all and (max-width: 1100px) {
  .News1Container {
    .News1Title {
      font-size: 21px;
      margin-right: 20px;
    }
    .News1Sub {
      font-size: 13px;
      padding-right: 20px;
    }
    .News1Sort {
      font-size: 13.5px;
    }
  }
}
//Mobile Tablet 미디어쿼리 분기점
@media all and (max-width: 1024px) {
  .News1Container {
    .New1Main {
      padding: 0 50px;
    }
    .News1Title {
      font-size: 30px;
      margin-right: 20px;
    }
    .News1TitleBox {
      padding-top: 50px;
      align-items: flex-start;
      position: relative;
    }
    .News1LeftBox {
      position: absolute;
      right: 0;
    }
    .News1RightBox {
      flex-direction: column;
      align-items: flex-start;
    }
    .News1Sub {
      font-size: 20px;
      padding-right: initial;
      color: #6c6c6c;
      padding-top: 10px;
    }
    .News1Sort {
      font-size: 13px;
      font-weight: bold;
      line-height: 3;
    }
  }
}

@media all and (max-width: 767px) {
  .News1Container {
    .New1Main {
      padding: 0 25px;
    }
    .News1Title {
      font-size: 28px;
    }
    .News1TitleBox {
      padding-top: 30px;
    }
    .News1Sub {
      font-size: 20px;
    }
    .News1Sort {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
@media all and (max-width: 580px) {
  .News1Container {
    .News1Title {
      font-size: 25px;
    }
    .News1Sub {
      font-size: 18px;
    }
    .News1Sort {
      font-size: 12px;
      line-height: 2.5;
    }
  }
}
@media all and (max-width: 500px) {
  .News1Container {
    .News1Title {
      font-size: 22px;
      white-space: initial;
      margin-right: 80px;
    }
    .News1Sub {
      font-size: 16px;
    }
    .News1Sort {
      font-size: 11px;
      line-height: 2.5;
    }
  }
}

@media all and (max-width: 400px) {
  .News1Container {
    .News1Title {
      font-size: 20px;
    }
    .News1Sub {
      font-size: 14px;
    }
    .News1Sort {
      font-size: 10px;
      line-height: 2.5;
    }
  }
}

@media all and (max-width: 330px) {
  .News1Container {
    .News1Title {
      font-size: 19px;
    }
    .News1Sub {
      font-size: 12px;
    }
    .News1Sort {
      font-size: 10px;
      line-height: 2.5;
    }
  }
}
