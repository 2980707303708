.Pdesign_container {
  overflow: hidden;
  height: 100%;
  background: #f1f2f4;
  padding: 100px 0;
  .Pdesign_cont {
    overflow: hidden;
    position: relative;
    .Pdesign_txt_bx {
      overflow: hidden;

      margin-top: 93px;
      .Pdesign_tit {
        font-size: 14px;
        font-weight: 500;
      }
      .Pdesign_txt {
        padding-top: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
    .Pdesign_image_Wrap {
      overflow: hidden;

      width: 100%;
      height: 100%;
      > img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .Pdesign_container {
    .Pdesign_cont {
      .col-6 {
        width: 100%;
      }
      &-wrapper {
        text-align: center;
      }
    }
  }
}
