.PbgPageFive_container {
  img {
    width: 100%;
  }
  .Five_con_1 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    background: #000 url(../../../../../_asset/images/Prorenata/img_prn26.png)
      no-repeat;
    background-size: contain;
  }
  .Five_con_2 {
    overflow: hidden;
    width: 100%;
    height: 50%;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn1.png) left top
      repeat-x;
  }
  .Five_con_3 {
    overflow: hidden;
    width: 100%;
    height: 50%;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn2.png) left top
      repeat-x;
    .box_Wrap03 {
      overflow: hidden;
    }
  }
  .Five_con_4 {
    overflow: hidden;
    width: 100%;
    height: 1000px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn3.png) left top
      repeat-x;
    .box_Wrap04 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn29.png)
        center top no-repeat;
    }
  }
  .Five_con_5 {
    overflow: hidden;
    width: 100%;
    height: 1000px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn4.png) left top
      repeat-x;
    .box_Wrap05 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn30.png)
        center top no-repeat;
    }
  }
  .Five_con_6 {
    overflow: hidden;
    width: 100%;
    height: 1000px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn5.png) left top
      repeat-x;
    .box_Wrap06 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn31.png)
        center top no-repeat;
    }
  }
  .Five_con_7 {
    overflow: hidden;
    width: 100%;
    height: 1000px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn6.png) left top
      repeat-x;
    .box_Wrap07 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn32.png)
        center top no-repeat;
    }
  }
  .Five_con_8 {
    overflow: hidden;
    width: 100%;
    height: 1000px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn6.png) left top
      repeat-x;
    .box_Wrap08 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn33.png)
        center top no-repeat;
    }
  }
  .PbgFive_tit {
    overflow: hidden;

    height: 202px;

    padding-top: 49px;
    background: #fff;
    > img {
      width: 100%;
    }
  }
}
