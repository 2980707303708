.FifthPageGrvina{
  max-width: 1290px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  
  margin: 0 auto;
  &__heading1 {
      padding-top: 190px;
      font-family: PretendardBold;
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #00dc16;
    }
    &__heading2 {
      font-family: PretendardRegular;
      font-size: 60px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin-bottom: 70px;
    }
    &__line {
      max-width: 450px;
      border: 1px solid white;
    }
    &__item {
      max-width: 450px;
      padding: 13px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      font-family: PretendardRegular;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    &__title-active {
      font-family: PretendardBold;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #00dc16;
    }
    &__arrow {
      max-height: 34px;
      max-width: 132px;
    }
    &__content {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      &-icon {
        position: absolute;
        left: -70px;
        top: 20px;
        height: 23px;
      }
      &-right {
        max-width: 610px;
        position: relative;
      }
    }
    &__slide {
      max-width: 609px;
      max-height: 373px;
      &-img {
        object-fit: contain;
        width: 100%;
      }
    }
    &__leftArrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(50%, 50%);
    }
    &__rightArrow {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, 50%);
    }
    .slick-arrow {
      width: 61px;
      height: 61px;
      z-index: 10;
    }
    &__desc {
      position: absolute;
      left: 0;
      bottom: -50px;
      font-family: PretendardBold;
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
	.FifthPageGrvina{
    &__content {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-right {
        max-width: 300px;
        position: relative;
      }
    }
	}
}
  
@media all and (max-width:1024px) {
	.FifthPageGrvina{
    padding-bottom: 100px;
    margin: 0 auto;
    text-align: center;
	  &__heading1 {
      padding-top: 50px;
      font-size: 24px;
      line-height: 1;
      text-align: center;
    }
    &__heading2 {
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 10px;
      text-align: center;
    }
    &__line {
      max-width: 100%;
      width: 100%;
    
      border: 1px solid white;
    }
    &__item {
      max-width: 100%;
      width: 100%;
      padding: 13px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      font-size: 14px;
    }
    &__title-active {
      font-size: 14px;
    }
    &__arrow {
      max-height: 34px;
      max-width: 50px;
    }
    &__content {
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-icon {
        position: absolute;
        left: -70px;
        top: 20px;
        height: 23px;
      }
      &-left {
        width: 100%;
        margin: 30px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-right {
        max-width: 300px;
        position: relative;
      }
    }
    &__slide {
      max-width: 300px;
      max-height: 373px;
      &-img {
        object-fit: contain;
        width: 100%;
      }
    }
    &__leftArrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(50%, 50%);
    }
    &__rightArrow {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, 50%);
    }
    .slick-arrow {
      width: 30px;
      height: 30px;
      z-index: 10;
    }
    &__desc {
      position: absolute;
      left: 0;
      bottom: -50px;
      font-size: 14px;
    }
    .slick-prev {
      transform: translate(35%, -50%) !important;
    }
    .slick-next {
      transform: translate(-35%, -50%) !important;
    }
	}
}