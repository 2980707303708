.EightPageGrvina {
  max-width: 1290px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;
  padding-top: 105px;
  margin: auto;
  &__title {
    margin-bottom: 110px;
    font-family: PretendardBold;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a19;
  }
  &__desc {
    font-family: PretendardRegular;
    font-size: 24.5px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a19;
    margin-top: 170px;

  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 22px;
  }
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 390px;
    width: 397px;
  }
  &__box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 390px;
    width: 397px;

  }
  &__sock {
    position: absolute;
    left: 70px;
    top: 110px;
    max-width: 239px;
    max-height: 224px;
  }
  &__arrow {
    position: absolute;
    bottom: -18px;
    right: -18px;
    width: 90px;
    height: 90px;
  }
  &__heading {
    font-family: PretendardExtraBold;
    font-size: 36px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-top: 30px;
    margin-left: 30px;
    z-index: 2;
  }
  &__text {
    font-family: PretendardMedium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    z-index: 2;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  
}

@media screen and (min-width: 1920px) {
  
}
@media all and (max-width: 1024px) {
  
}
@media all and (max-width: 768px) {
  .EightPageGrvina {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    &__title {
      margin-bottom: 50px;
      font-size: 24px;
    }
    &__desc {
      font-size: 16px;
      margin-top: 50px;

    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 300px;
      width: 300px;
    }
    &__box {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: 300px;
      width: 300px;

    }
    &__sock {
      position: absolute;
      left: 50px;
      top: 55px;
      max-width: 200px;
      max-height: 200px;
    }
    &__arrow {
      position: absolute;
      bottom: 1px;
      right: -6px;
      width: 60px;
      height: 60px;
    }
    &__heading {
      font-size: 16px;
      margin-top: 30px;
      margin-left: 30px;
    }
    &__text {
      font-size: 16px;
      margin-left: 30px;
      margin-bottom: 30px;
    }
  }
}

