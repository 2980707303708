.MuiPaper-rounded {
	border-radius: 0 !important;
}

.closeBtn {
	position: fixed;
	right: 20px;
	top: 10px;
	width: 40px;
	height: 40px;
	z-index: 99;
	cursor: pointer;

	.closeBar1,
	.closeBar2 {
		width: 15px;
		height: 2px;
		background-color: #858585;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		position: relative;
		top: 16px;
		left: 5px;
	}

	.closeBar1 {
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.closeBar2 {
		transform: rotate(-45deg);
		margin-top: -2px;
	}

	&:hover {
		.closeBar1,
		.closeBar2 {
			background-color: black;
		}
	}
}

.content-dialog-container {
	//컨텐츠박스 스타일링
}
