.Ecouponcontainer {
  padding: 100px 0;
  background: #f0f0f0;
  overflow: hidden;
  .con_bx {
    .coupon_sub {
      overflow: hidden;
      margin-top: 33px;
      color: #808080;
      font-size: 16px;
      font-weight: 500;
      line-height: 38px;
    }
    .list {
      margin-top: 147px;
      font-size: 0;
      line-height: 0;

      .coupon_Wrap {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        > img {
          box-shadow: 20px 20px 40px rgba(34, 34, 34, 0.15);
          margin-bottom: 10px;
        }
        .frame {
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Ecouponcontainer {
    .list {
      .col-6 {
        width: 100%;
      }
    }
  }
}
