.Pinstagram_container {
  overflow: hidden;
  .Pinstagram_cont_1 {
    position: relative;
    width: 100%;
    height: 1745px;
    background: #000;
    z-index: 20;
    .instargrom_bx_1 {
      position: absolute;
      left: 0;
      top: 138px;
      width: 100%;
      height: 1644px;
      background: url(../../../../../_asset/images/Prorenata/img_prn54.png)
        center top no-repeat;
    }
  }
  .Pinstagram_cont_2 {
    position: relative;
    width: 100%;
    height: 2179px;
    background: #eef0f3;
    z-index: 10;
    .instargrom_bx_2 {
      position: absolute;
      left: 0;
      top: 187px;
      width: 100%;
      height: 1992px;
      background: url(../../../../../_asset/images/Prorenata/img_prn55.png)
        center top no-repeat;
    }
  }
  .Pinstagram_cont_3 {
    position: relative;
    width: 100%;
    height: 2160px;
    background: #fafbfb;
    .instargrom_bx_3 {
      position: absolute;
      left: 0;
      top: 138px;
      width: 100%;
      height: 2022px;
      background: url(../../../../../_asset/images/Prorenata/img_prn56.png)
        center top no-repeat;
    }
  }
  .Pinstagram_tit {
    display: flex;
    overflow: hidden;
    width: 960px;
    height: 202px;
    margin: 0 auto;
    padding-top: 49px;
    background: #fff;
    justify-content: space-between;
    .Left_bx {
      font-size: 12px;
    }
    .Right_bx {
      font-size: 12px;
      text-align: right;
    }
  }
}
